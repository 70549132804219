import Config from '../config'
import fetcher from '../lib/fetcher'

export const init = (body: {
  _origin: string
  _referrer: string
  _goid?: string | null
}) => {
  delete Config.visitor_id
  return fetcher<{ _goid: string }>('visitor/api/init/').setConfig(Config).post(body)
}

export const register = (body: {
  _goid: string,
  visitor_id?: string,
  contact?: {
    external_app?: string
    external_model?: string
    external_id?: number
    first_name?: string
    last_name?: string
    email?: string
    mobile_no?: string
  }
}) =>
  fetcher<{
    visitor_id: number
    _goid: string
    contact: {
      external_app?: string
      external_model?: string
      external_id?: number
      first_name?: string
      last_name?: string
      email?: string
      mobile_no?: string
    }
  }>('visitor/api/register/')
    .setConfig(Config)
    .post(body)

export const logout = (body: { _goid: string }) => 
  fetcher('visitor/api/reset/').setConfig(Config).post(body)

export const logEvent = (body: { name: string; data: any }) =>
  fetcher('visitor/api/log_event/').setConfig(Config).post({
    _goid: Config._goid,
    event_name: body.name,
    event_data: body.data
  })
