import { FC, useState } from 'react'
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant'
import Text from '../Text'
import { useChat, useVisitor, useApp } from 'alpha-chat';
import Container from './Container';
import InputTypeForm from '../ChatBot/InputTypeForm';
import Log from '../../utils/log';

const RegisterView: FC<{
  deviceType?: DEVICE_TYPE
  onToggle?: () => void
  onToggleCurrentView?: (value: VIEW_TYPE, conversationKey?: any) => void
}> = ({deviceType, onToggle, onToggleCurrentView}) => {
   const [isLoading, setIsLoading] = useState(false)

   const { app_settings } = useApp()
   const { _goid, register } = useVisitor()
   const {
      newConversation,
      conversations,
      setIsFetching
   } = useChat()

   const onSubmit = (text: string) => {
      setIsLoading(true)

      const data  = JSON.parse(text)
      if(_goid && data.form_values){
         register(_goid, data.form_values).then(({ok, data}) => {
            setIsLoading(false)

            if(ok && Object.keys(data?.contact).length > 0){
               onToggleCurrentView && onToggleCurrentView('CHAT')
               window.top?.postMessage(
                  {
                     updateSession: {
                        key: 'has_conversation',
                        value: true,
                        isUpdateIfExist: false,
                     }
                  },
                  '*'
                )
               setIsFetching(true)
            } else {
               Log('contact undefined')
            }
         })
      }
   }
   
   const fields = app_settings?.contact_form?.fields ? 
      app_settings?.contact_form?.fields?.map((data: any) => ({
         ...data,
         field_name: data.name,
         input_type: data.type,
         label: data.label,
         required: data.required ? '1' : '0',
         validation: data.type
      })) :
      []

   return (
      <Container
         deviceType={deviceType}
         onToggle={onToggle}
         onToggleCurrentView={onToggleCurrentView}
         intro={false}
         showBackButton>
         <div className="m-5 py-5 rounded-lg bg-white" style={{boxShadow: "0px 6px 11px 0px rgb(159 159 159 / 45%)"}}>
            <Text className="text-2xl font-semibold p-5">{app_settings?.contact_form?.title || ''}</Text>
            <InputTypeForm 
               className="bg-white p-5 pt-0 rounded-none border-0 shadow-none" 
               onClick={onSubmit} 
               titleButton="Continue"
               clasNameButton="!mt-6"
               {...{
                  fields,
                  isLoading
               }} />
         </div>
      </Container>
   )
}

export default RegisterView