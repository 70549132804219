import language from "../lib/locales";
import 'moment/locale/id';

const getLanguange = () => {
   let lang = language.en
   let langCode = 'en'
   
   const goappLang = localStorage.getItem('goapp-lang')
   if(goappLang){
      const goappLangParse = JSON.parse(goappLang)
      
      lang = goappLangParse?.lang
      langCode = goappLangParse?.langCode
   }
   return { lang: lang, langCode: langCode }
}

export default getLanguange