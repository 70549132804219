import Config from '../config'
import fetcher from '../lib/fetcher'
import { Conversation, Message } from '../lib/types'

export const getConversations = (filters?: { size?: number; offset: number }) =>
  fetcher<Array<Conversation>>(`conversation/api/conversation/`)
    .setConfig(Config)
    .get({
      size: 20,
      offset: 0,
      ...filters
    })

export const getConversationDetail = (conversationKey: number) =>
  fetcher(`conversation/api/conversation/${conversationKey}/`)
    .setConfig(Config)
    .get()

export const getConversationMessages = ({
  conversationKey, filters, signal
}: { conversationKey: number, filters?: { size: number; startFrom: number }, signal?: AbortSignal }) =>
  fetcher(`conversation/api/conversation/${conversationKey}/`)
    .setConfig(Config)
    .get({
      message_size: filters?.size || 20,
      message_start_from: filters?.startFrom || 0
    }, { signal })

export const newConversation = () =>
  fetcher(`conversation/api/conversation/start/`).setConfig(Config).post()

export const markAsRead = (conversationKey: number) =>
  fetcher(`conversation/api/conversation/${conversationKey}/read_all/`)
    .setConfig(Config)
    .post()

export const startTyping = (conversationKey: number) =>
  fetcher(`conversation/api/conversation/${conversationKey}/start_typing/`)
    .setConfig(Config)
    .post()

export const sendMessage = ({
  conversationKey,
  text,
  attachments
}: {
  conversationKey: number
  text: string
  attachments?: Array<any>
}) => {
  return fetcher<Message>(
    `conversation/api/conversation/${conversationKey}/send_message/`
  )
    .setConfig(Config)
    .post({ body: text, attachments })
}

export const uploadMedia = (
  { conversationKey, media }: { conversationKey: number; media: File },
  options?: RequestInit
) => {
  const body = new FormData()
  body.append('media', media)

  return fetcher(
    `conversation/api/conversation/${conversationKey}/upload_media/`
  )
    .setConfig(Config)
    .post(body, options)
}
