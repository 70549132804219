import React, { useEffect, useMemo, useState } from 'react'
import {
  getBusiness as getBusinessApi,
  init as initApi,
  logEvent as logEventApi,
  register as registerApi,
  logout as logoutApi
} from '../api'
import Log from '../log'
import { Business } from '../lib/types'
import Config, { SetConfig } from '../config'
import { useApp } from './app'

interface State {
  initialized?: boolean
  countReconnecting: number
  _goid?: string | null
  visitor_id?: string | null
  visitor_data?: any
  business?: Business
  initializingVisitor: (contact?: Register) => Promise<any>
  logEvent: (name: string, data: any) => Promise<any>
  register: (_goid: string, data?: Register) => Promise<any>
  logout: (_goid: string, contact?: Register) => Promise<any>
}

const initialState = {
  business: undefined,
  visitor_data: undefined,
  countReconnecting: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initializingVisitor: (contact?: Register) => Promise.resolve({}),
  logEvent: (name: string, data: any) => Promise.resolve({}),
  register: (_goid: string, data?: Register) => Promise.resolve({}),
  logout: (_goid: string, contact?: Register) => Promise.resolve({})
}

type Action =
  | { type: 'SET_GOID'; value?: string | null }
  | { type: 'SET_VISITOR_INITIALIZE'; value?: boolean}
  | { type: 'SET_VISITOR_ID'; value?: string | null }
  | { type: 'SET_VISITOR_DATA'; value?: any | null }
  | { type: 'SET_BUSINESS'; value?: Business }
  | { type: 'SET_COUNT_RECONNECTING', value?: number }
  | { type: 'RESET_VISITOR' }

type Register = {
  external_app?: string
  external_model?: string
  external_id?: number
  first_name?: string
  last_name?: string
  email?: string
  mobile_no?: string
}

export const VisitorContext = React.createContext<State>(initialState)
VisitorContext.displayName = 'VisitorContext'

function visitorReducer(state: State, action: Action) {
  Log(action)
  switch (action.type) {
    case 'SET_GOID': {
      SetConfig({ _goid: action.value })
      return {
        ...state,
        _goid: action.value
      }
    }
    case 'SET_VISITOR_INITIALIZE': {
      return {
        ...state,
        initialized: action.value
      }
    }
    case 'SET_VISITOR_ID': {
      SetConfig({ visitor_id: action.value })
      return {
        ...state,
        visitor_id: action.value,
        initialized: true
      }
    }
    case 'SET_VISITOR_DATA': {
      return {
        ...state,
        visitor_data: action.value,
      }
    }
    case 'SET_BUSINESS': {
      SetConfig({ business: action.value })
      return {
        ...state,
        business: action.value
      }
    }
    case 'SET_COUNT_RECONNECTING': {
      return { ...state, countReconnecting: action.value || state.countReconnecting++ }
    }
    case 'RESET_VISITOR': {
      const resetVisitor = {
        visitor_id: null,
        visitor_data: null,
        initialized: false
      }

      SetConfig({visitor_id: '', contact: ''})
      return {
        ...state,
        ...resetVisitor
      }
    }
  }
}

export const VisitorProvider: React.FC = (props): JSX.Element => {
  const [state, dispatch] = React.useReducer(visitorReducer, initialState)
  const [reconnectingInterval, setReconnectingInterval] =
    useState<NodeJS.Timeout>()
  const [initializing, setInitializing] = useState<boolean>()
  const { app_settings } = useApp()

  const init = () => {
    const visitor = {
      _referrer: document.referrer,
      _origin: window.location.href,
      _goid: Config._goid
    }

    return initApi(visitor).then(({ ok, data, ...rest }) => {
      ok && dispatch({ type: 'SET_GOID', value: data._goid.toString() })
      return { ok, data, ...rest }
    })
  }

  const register = (_goid: string, data?: Register) => {
    const visitor = { _goid: _goid.toString() }
    let contact = {}

    if(data){
      Log('data register', data)
      Object.keys(data).forEach(k => {
        contact[k] = data[k]
      })

      visitor['contact'] = contact
      visitor['visitor_id'] = state.visitor_id
      
    } else {
      // const lsRegister = localStorage.getItem('goapp-chat-widget-session-register')
      // if(lsRegister){
      //   Log('lsRegister', JSON.parse(lsRegister))

      //   const parseLsRegister = JSON.parse(lsRegister)
      //   Object.keys(parseLsRegister).forEach(k => {
      //     contact[k] = parseLsRegister[k]
      //   })
  
      //   visitor['contact'] = contact
      //   visitor['visitor_id'] = state.visitor_id
      // }
    }
    
    return registerApi(visitor).then(({ ok, data, ...rest }) => {
      if(ok){
        if(Object.keys(data?.contact).length == 0){
          Log('contact undefined')
        }
        
        dispatch({ type: 'SET_VISITOR_ID', value: data.visitor_id.toString() })
        dispatch({ type: 'SET_VISITOR_DATA', value: data?.contact })

        // localStorage.removeItem('goapp-chat-widget-session-register')

      }

      return { ok, data, ...rest }
    })
  }

  const logEvent = (name: string, data: any) => logEventApi({ name, data })

  const getBusiness = (visitor_id: number) =>
    getBusinessApi({ visitor: visitor_id }).then(({ ok, data, ...rest }) => {
      ok && dispatch({ type: 'SET_BUSINESS', value: data })

      return { ok, data, ...rest }
    })

  useEffect(() => {
    Log(state, 'visitorContext')
  }, [state])

  useEffect(() => {
    if (state.initialized) {
      if (reconnectingInterval) {
        clearInterval(reconnectingInterval)
        setReconnectingInterval(undefined)
      }

      return
    }

    if(app_settings?.['chat-widget']?.initialize_on_load || (Config._goid && Config.visitor_id && Config.has_conversation)){
      initializingVisitor()
    } else {
      Log('open chat widget for initialize')
    }
    
  }, [state.initialized, app_settings])

  const initializingVisitor = (contact?: Register) => new Promise<any>((resolve, reject) => {
    if (!initializing) {
      setInitializing(true)
      init().then(({ ok, data }) => {
        if(ok){
          register(data._goid, contact || Config.contact).then(({ ok, data }) => {
            if(ok){
              getBusiness(data.visitor_id)
              setInitializing(false)
              resolve({ok: true, data: data.visitor_id})
            } else {
              dispatch({
                type: 'SET_COUNT_RECONNECTING',
                value: 3
              })
              resolve({ok: true, data: null})
            }
          })
        } else {
          dispatch({
            type: 'SET_COUNT_RECONNECTING',
            value: 3
          })
          resolve({ok: true, data: null})
        }
      })
    } else {
      resolve({ok: true, data: null})
    }
  })

  const logout = async (_goid: string, contact?: Register) => {
    const visitor = { _goid: _goid.toString() }
    
    return await logoutApi(visitor).then(async ({ ok, data, ...rest }) => {
      if(ok){
        await initializingVisitor(contact)
      }

      return { ok, data, ...rest }
    })
  }


  const memoValue = useMemo(
    () => ({
      ...state,
      initializingVisitor,
      register,
      logout,
      logEvent
    }),
    [state]
  )

  return <VisitorContext.Provider value={memoValue} {...props} />
}

export const useVisitor = () => {
  const context = React.useContext(VisitorContext)
  if (context === undefined) {
    throw new Error(`useVisitor must be used within a VisitorProvider`)
  }
  return context
}
