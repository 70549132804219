import cn from 'clsx'
import { FC, useRef } from 'react'
import { MdAttachFile, MdSend } from 'react-icons/md'
import { useLang } from 'alpha-chat'
import { DEVICE_TYPE } from '../../utils/constant'

export type ChatActionBarProps = {
  value: string
  onChangeValue?: (value: string) => void
  onChangeAttachment?: (files: FileList | null) => void
  onEnter?: () => void
  canSendMessage?: boolean
  disabled?: boolean
  buttonColor?: string
  deviceType?: DEVICE_TYPE
}

const ChatActionBar: FC<ChatActionBarProps> = ({
  value,
  onChangeValue,
  onChangeAttachment,
  onEnter,
  canSendMessage,
  disabled,
  buttonColor = '',
  deviceType
}) => {
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null)
  const { lang } = useLang()

  return (
    <div className='flex flex-row w-full items-center'>
      <div className={cn(
        "w-full flex mr-2 p-2 bg-slate-100 relative rounded",
        {"!bg-slate-50": disabled}
      )}>
        <textarea
          className={cn(
            'w-full outline-none ',
            'bg-slate-100 disabled:bg-slate-50'
          )}
          placeholder={lang.type_your_message}
          ref={inputRef}
          value={value}
          onChange={(e) => onChangeValue && onChangeValue(e.target.value)}
          disabled={disabled}
          onKeyUp={(ev) => {
            if (ev.key === 'Enter' && !ev.shiftKey && deviceType == 'DESKTOP'){
              if(inputRef.current) inputRef.current.style.height  = '20px' // 1 line textarea
              ev.preventDefault()
              onEnter && onEnter()
            }
          }}
          rows={1}
          style={{
            resize: 'none',
            minHeight: '20px',
            maxHeight: '100px' // max 5 line
          }}
          onInput={(e) => {
            (e.target as HTMLElement).style.height = "20px";
            (e.target as HTMLElement).style.height = ((e.target as HTMLElement).scrollHeight)+"px";
          }}
        />
      </div>
      {
        !disabled && (
          <button
            className={cn(
              'self-end relative rounded overflow-hidden bg-slate-100 disabled:bg-slate-50'
            )}
            style={{ padding: 18 }}
            disabled={disabled}
          >
            <MdAttachFile
              size={20}
              className={cn('absolute left-2 top-2', {
                'text-slate-400': disabled,
                'text-slate-600': !disabled
              })}
            />
            <input
              type='file'
              multiple
              className={cn('absolute top-0 bottom-0 right-0 opacity-0', {
                'cursor-pointer': !disabled
              })}
              onChange={(e) =>
                onChangeAttachment && onChangeAttachment(e.target.files)
              }
              disabled={disabled}
            />
          </button>
        )
      }
      <button
        className={cn('self-end bg-slate-600 disabled:bg-slate-400 rounded p-2 ml-2', {
          'cursor-pointer': !disabled
        })}
        style={{backgroundColor: !disabled ? buttonColor : 'rgb(148 163 184)'}}
        disabled={!canSendMessage}
        onClick={(ev) => {
          ev.preventDefault()
          if(inputRef.current) {
            inputRef.current.style.height  = '20px' // 1 line textarea
            inputRef.current.focus()
          }
          onEnter && onEnter()
        }}
      >
        <MdSend size={20} className='text-white' />
      </button>
    </div>
  )
}

export default ChatActionBar
