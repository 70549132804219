import cn from 'clsx'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { MdClose, MdMoreVert, MdOutlineArrowBackIosNew } from 'react-icons/md'
import { useApp, useChat, useLang, useVisitor } from 'alpha-chat'
import Text from '../Text'
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant'

const chatWidth = 376

type ChatHeaderProps = {
  isConnected: boolean
  showCloseButton?: boolean
  onToggle?: () => void
  onToggleCurrentView?: (value: VIEW_TYPE) => void
  deviceType?: DEVICE_TYPE,
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  isConnected,
  showCloseButton,
  onToggle,
  onToggleCurrentView,
  deviceType
}) => {
  const { app_settings } = useApp()
  const { business } = useVisitor()
  const { lang } = useLang()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>()
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  const primaryColor = app_settings?.['chat-widget']?.ui?.header_color ? 
                           app_settings?.['chat-widget']?.ui?.header_color : 
                           'rgb(71, 85, 105)'

  const iconHeader = app_settings?.['chat-widget']?.ui?.header_icon || business?.avatar?.image_url

  useEffect(() => {
    const handleClickOutside = (ev: any) => {
      if (ref.current && !ref.current.contains(ev.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside, true)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [])

  return (
    <div
      className={cn(
        'flex flex-row p-4 space-x-4',
        'border-b border-b-solid border-b-slate-200',
        'items-center'
      )}
      style={{
        backgroundColor: primaryColor,
        borderRadius: deviceType === 'DESKTOP' ? `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px` : ''
      }}
    >
      <div 
        className='cursor-pointer' 
        onClick={() => {
          onToggleCurrentView && onToggleCurrentView('HOME')
        }}>
        <MdOutlineArrowBackIosNew className='text-slate-200' size={23} />
      </div>
      {iconHeader ? (
        <img
          src={iconHeader}
          className='h-10 aspect-square rounded-full'
        />
      ) : (
        <div className='h-10 aspect-square bg-slate-200 rounded-full' />
      )}
      <div className='flex flex-col flex-1'>
        <Text className={cn('font-bold text-white truncate')} classNameString="truncate" style={{maxWidth: deviceType == 'DESKTOP'? chatWidth - 186 : window.innerWidth * 2 / 4}}>
          {app_settings?.['chat-widget']?.default_operator_name || business?.name || ' '}
        </Text>
        <div className='flex flex-row items-center space-x-2'>
          <div
            className={cn('rounded-full w-3 h-3', {
              'bg-rose-600': !isConnected,
              'bg-green-600': isConnected
            })}
          />
          <div className='text-xs text-white text-opacity-75'>
            {isConnected ? lang.connected : lang.connecting}
          </div>
        </div>
      </div>
      <div className='relative' ref={ref}>
        <MdClose 
          className='cursor-pointer text-slate-200' 
          onClick={() => {
            onToggle && onToggle()
          }}
          size={28} />
      </div>
    </div>
  )
}

export default ChatHeader
