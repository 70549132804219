import React, { useEffect, useMemo } from "react";
import language from "../lib/locales";
import Log from "../log";
import { useApp } from "./app";

interface State {
   lang: any,
   langCode: string
}

const initialState = {
   lang: language.en,
   langCode: 'en'
}

type Action = 
   | { type : 'SET_LANG'; lang: any, langCode: string}

export const LangContext = React.createContext<State>(initialState)
LangContext.displayName = 'LangContext'

function langReducer(state: State, action: Action){
   Log(action)
   switch (action.type) {
      case 'SET_LANG': {
         const newState = {
            lang: action.lang,
            langCode: action.langCode
         }

         localStorage.setItem('goapp-lang', JSON.stringify(newState))
         return {
            ...state,
            ...newState
         }
      }
   }
}

export const LangProvider: React.FC = (props): JSX.Element => {
   const [state, dispatch] = React.useReducer(langReducer, initialState)
   const { app_settings } = useApp()

   useEffect(() => {
     if(app_settings){
        dispatch({
           type: 'SET_LANG',
           lang: language[app_settings["chat-widget"].language] || language.en,
           langCode: language[app_settings["chat-widget"].language] ? app_settings["chat-widget"].language : 'en'
        })
     }
   }, [app_settings])

   // useEffect(() => {
   //    Log(state, 'LangContext')
   //  }, [state])

   const memoValue = useMemo(
      () => (state),
      [state]
    )

    return <LangContext.Provider value={memoValue} {...props} />
}

export const useLang = () => {
   const context = React.useContext(LangContext)
   if(context === undefined){
      throw new Error(`useLang must be used within a LangProvider`)
   }
   return context
}