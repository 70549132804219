import React, { useEffect, useMemo } from "react";
import {
   getApplication as getApplicationApi,
 } from '../api'
import { AppSettings } from "../lib/types";
import Log from "../log";

interface State {
   initialized: boolean,
   initializedOnLogin?: boolean,
   app_settings?: AppSettings,
   getApplicationSettings: () => void
   setInitOnLogin: (v?: boolean) => void
}

const initialState = {
   initialized: false,
   initializedOnLogin: false,
   app_settings: undefined,
   getApplicationSettings: () => ({}),
   setInitOnLogin: (v?: boolean) => ({})
}

type Action = 
   | { type : 'SET_APP_SETTINGS'; value?: AppSettings}
   | { type : 'SET_INIT_ON_LOGIN'; value?: boolean}

export const AppContext = React.createContext<State>(initialState)
AppContext.displayName = 'AppContext'

function appReducer(state: State, action: Action){
   Log(action)
   switch (action.type) {
      case 'SET_APP_SETTINGS': {
         return {
            ...state,
            initialized: true,
            app_settings: action.value
         }
      }
      case 'SET_INIT_ON_LOGIN': {
         return {
            ...state,
            initializedOnLogin: action?.value
         }
      }
   }
}

export const AppProvider: React.FC = (props): JSX.Element => {
   const [state, dispatch] = React.useReducer(appReducer, initialState)
   // const [initializing, setInitializing] = useState<boolean>()

   const getApplicationSettings = () => {
      getApplicationApi().then(({ok, data}) => {
         if(ok){
            dispatch({ 
               type: 'SET_APP_SETTINGS', 
               value: {
                  ...data?.app_settings,
                  'chat-widget': {
                     ...data?.app_settings['chat-widget'],
                     hide_goapp_label: data?.app_settings?.['chat-widget']?.hide_goapp_label || false,
                     google_font: data?.app_settings?.['chat-widget']?.google_font || '',
                     initialize_on_load: data?.app_settings?.['chat-widget']?.initialize_on_load !== undefined ? 
                                          data?.app_settings?.['chat-widget']?.initialize_on_load : 
                                          false,
                     language: data?.app_settings?.['chat-widget']?.language || 'en',
                     ui: {
                        ...data?.app_settings?.['chat-widget']?.ui,
                        header_color: data?.app_settings?.['chat-widget']?.ui?.header_color || data?.app_settings?.['chat-widget']?.ui?.primary_color,
                     },
                  }
               }
            })
         }
      })
    }

   const setInitOnLogin = (value = true) => {
      dispatch({ 
         type: 'SET_INIT_ON_LOGIN', 
         value
      })
   }

   useEffect(() => {
      !state.initialized && getApplicationSettings()
   }, [])

   useEffect(() => {
      Log(state, 'appContext')
    }, [state])

   const memoValue = useMemo(
      () => ({
        ...state,
        getApplicationSettings,
        setInitOnLogin
      }),
      [state]
    )

   return <AppContext.Provider value={memoValue} {...props} />
}

export const useApp = () => {
   const context = React.useContext(AppContext)
   if(context === undefined){
      throw new Error(`useApp must be used within a AppProvider`)
   }
   return context
}