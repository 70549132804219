import { FC } from 'react'
import { useChat, useVisitor, useApp, useLang, getTimeOfDay } from 'alpha-chat';
import cn from 'clsx'
import Text from '../Text'
import HomeHeader from './HomeHeader';
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant';

const chatWidth = 376

const Container: FC<{
   deviceType?: DEVICE_TYPE
   onToggle?: () => void
   onToggleCurrentView?: (value: VIEW_TYPE, conversationKey?: any) => void
   intro?: boolean,
   showBackButton?: boolean
}> = ({deviceType, onToggle, onToggleCurrentView, intro = true, showBackButton, children}) => {
   
   const { app_settings } = useApp()
   const { visitor_data } = useVisitor()
   const { lang } = useLang()

   const primaryColor = app_settings?.['chat-widget']?.ui?.header_color ? 
                           app_settings?.['chat-widget']?.ui?.header_color : 
                           'rgb(71, 85, 105)'

   return (
      <>
         <HomeHeader 
            primaryColor={primaryColor}
            deviceType={deviceType}
            onToggle={onToggle}
            onToggleCurrentView={onToggleCurrentView}
            showBackButton={showBackButton}
         />
         <div 
            className={cn("absolute h-[320px]", )} 
            style={{
               backgroundColor: primaryColor, 
               width: deviceType === 'DESKTOP' ? chatWidth - 16 : '100%',
               borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px`
            }}>
         </div>

         <div className={cn(
            "relative overflow-y-auto",
            {
               "pb-16": !app_settings?.['chat-widget'].hide_goapp_label,
               "pb-4": app_settings?.['chat-widget'].hide_goapp_label
            }
         )}>
            {
               intro && (
                  <div className="relative">
                     <div className="px-6 py-3 text-white">
                        <Text className="text-2xl font-semibold mb-2">
                           {
                              visitor_data?.first_name ? 
                                 app_settings?.['chat-widget'].strings?.greeting
                                 .replace('{{first_name}}', visitor_data?.first_name || '')
                                 .replace('{{time_of_day}}', getTimeOfDay())
                                 .replace('{{last_name}}', visitor_data?.last_name || '') :
                                    app_settings?.['chat-widget'].strings?.guest_greeting
                                    .replace('{{time_of_day}}', getTimeOfDay()) 
                                    || 'Hi!'
                           }
                        </Text>
                        <Text>{app_settings?.['chat-widget'].strings?.intro || lang.how_we_can_help_you}</Text>
                     </div>
                  </div>
               )
            }

            {children}
         </div>
      </>
   )
}

export default Container