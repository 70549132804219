import moment from 'moment'
import { Conversation, LocalChatEvent } from '../lib/types'
import 'moment/locale/id';
import getLanguange from './getLanguage'

const getEventsByConversation = (conversation: Conversation) => {
  const events = [] as Array<LocalChatEvent>
  let lang = getLanguange().lang
  let langCode = getLanguange().langCode
  
  if (conversation.created_at) {
    const created_at = moment( conversation?.transferring_conversation?.created_at || conversation.created_at)
    events.push({
      id: events.length + 1,
      type: 'date',
      eventDate: created_at,
      text: moment().isSame(created_at, 'day')
        ? lang.today
        : created_at.format('ddd, D MMM YYYY')
    })
    // events.push({
    //   id: events.length + 1,
    //   type: 'event',
    //   eventDate: created_at,
    //   text: `${lang.started_by} ${`
    //   ${conversation.initiated_by?.contact?.first_name || 'You'} 
    //   ${conversation.initiated_by?.contact?.last_name || ''}
    //   `} ${lang.at} ${moment(conversation.created_at).locale(langCode).format('LLLL')}`
    //   // text: `${moment(conversation.created_at).locale(langCode).format('LLLL')}`
    // })
  }
  if (conversation.queued_at) {
    const queued_at = moment(conversation.queued_at)
    events.push({
      id: events.length + 1,
      type: 'event',
      eventDate: queued_at,
      text: `Transfered to ${conversation.queue.name} at ${queued_at.locale(langCode).format('LLLL')}`
    })
  }
  if (conversation.assigned_at) {
    const assigned_at = moment(conversation.assigned_at)
    const assigned_to = `${conversation.assigned_to?.first_name || ""} ${conversation.assigned_to?.last_name || ""}`.trim() || `Contact ${conversation.assigned_to?.uid % 9999}`
    events.push({
      id: events.length + 1,
      type: 'event',
      eventDate: assigned_at,
      text: `Assigned to ${assigned_to} at ${assigned_at.locale(langCode).format('LLLL')}`
    })
  }
  // if (conversation.closed_at) {
  //   const closed_at = moment(conversation.closed_at)
  //   const closed_by = conversation.closed_by
  //     ? `${conversation.closed_by.first_name} ${
  //         conversation.closed_by.last_name || ''
  //       }`
  //     : 'System'
  //   events.push({
  //     id: events.length + 1,
  //     type: 'event',
  //     eventDate: closed_at,
  //     text: `${lang.ended_by} ${closed_by} at ${closed_at.locale(langCode).format('LLLL')}}`
  //   })
  // }
  if (conversation.last_message) {
    const created_at = moment(conversation.last_message.created_at).startOf(
      'day'
    )
    const different_in_day = created_at.diff(
      moment(conversation.created_at).startOf('day'),
      'days'
    )

    if(Array.isArray(different_in_day)){
      Array(different_in_day)
        .fill(different_in_day)
        .forEach((_, index) => {
          const day_at = moment(conversation.created_at)
            .startOf('day')
            .add(index + 1, 'day')
  
          events.push({
            id: events.length + 1,
            type: 'date',
            eventDate: day_at,
            text: moment().isSame(day_at, 'day')
              ? lang.today
              : day_at.format('ddd, D MMM YYYY')
          })
        })
    }
  }

  return events
}

export default getEventsByConversation
