const id = {
   "please_wait": "Mohon tunggu...",
   "connected": "Terhubung",
   "connecting": "Menghubungkan",
   "started_by": "Dimulai oleh",
   "ended_by": "Diakhiri oleh",
   "at": "pada",
   "sending": "mengirim",
   "read": "terbaca",
   "delivered": "terkirim",
   "sent": "terkirim",
   "failed": "gagal",
   "pending": "tertunda",
   "type_your_message": 'Ketik Pesan Anda',
   "is_typing": 'sedang mengetik',

   "today": "Hari ini",
   "welcome": "Selamat Datang",
   "how_we_can_help_you": "Bagaimana kami dapat membantu Anda?",
   "start_conversation": "Mulai percakapan baru",
   "continue_the_conversation": "Lanjutkan percakapan",
   "hi_i_would_like": "Hi, saya mau...",
   "talk_to_us_on_your_favorite_channels": "Hubungi kami di kanal favorit anda"
}

export default id