import './assets/main.css'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

const root = document.getElementById('root')
root?.classList.add('root-chat-widget')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
)
