import moment from "moment"

const IDNumberFormatter = Intl.NumberFormat('ID', {
  style: 'currency',
  currency: 'IDR',
})

export const formatValue = (value, format) => {
  if (value === null || value === undefined || value === "")
    return ""
    
  if (!format)
    return value
  
  let formatArr = format.split("|")  
  const type = formatArr[0]
  const param = formatArr.length > 1 && formatArr[1]
    
  if (type == 'currency') {
    return IDNumberFormatter.format(
       Number.parseFloat(value).toFixed(0)
      ).replace(",00", "") 
  }
  else if (type == 'percent') {
    let p = Math.floor(value * 100)
    return `${p}%`
  }
  else if (type == 'datetime') {
    if (param == "fromnow")
      return moment(value).fromNow()
    else if (param == "relative" || param == "relative-long") {
      const time = moment(value)    
      if (time.isSame(new Date(), "day"))
        return time.format("HH:mm")
      else if (time.isSame(moment().subtract(1, 'days'), "day")) {
        if (param == "relative-long")
          return "Yesterday " + time.format("HH:mm")
        return "Yesterday"
      }
      else {
        if (param == "relative-long")        
          return time.format("DD MMM yyy HH:mm")
        return time.format("DD MMM yyy")
      }
    }

    return moment(value).format(param || "LL")
  }
  return value
}

export default formatValue