const getTimeOfDay = () => {
   const now = new Date();
   const hours = now.getHours();

   let timeOfDay;

   if (hours >= 0 && hours < 11) {
      timeOfDay = 'pagi';
   } else if (hours >= 11 && hours < 15) {
      timeOfDay = 'siang';
   } else if (hours >= 15 && hours < 18) {
      timeOfDay = 'sore';
   } else {
      timeOfDay = 'malam';
   }

   return timeOfDay
}

export default getTimeOfDay