import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Config from '../config'
import {
  getConversations as getConversationsApi,
  getConversationDetail as getConversationDetailApi,
  getConversationMessages as getConversationMessagesApi,
  sendMessage as sendMessageApi,
  startTyping as startTypingApi,
  markAsRead as markAsReadApi,
  newConversation as newConversationApi
} from '../api'
import { Conversation, Message, LocalChatMessage } from '../lib/types'
import { useVisitor } from './visitor'
import Log from '../log'
import { uniqueMergeArray } from '../utils'

export type VIEW_TYPE = 'HOME' | 'CHAT' | 'REGISTER' | 'LOGIN'

type State = {
  view: VIEW_TYPE,
  socket?: WebSocket
  isOpenView?: boolean
  isConnected: boolean
  isFetching: boolean
  initialized: boolean
  conversations: Array<Conversation>
  messages: Array<Message>
  pendingMessages: Array<LocalChatMessage>
  conversation?: Conversation
  startingNewConversation?: boolean
  disableSendMessage?: boolean
  newMessageCount: number,
  notifNewMessage: number,
  setView: (view: VIEW_TYPE) => void,
  initializeSocket: () => void
  getConversations: () => Promise<any>
  sendMessage: (
    conversationKey: number,
    text: string,
    files?: Array<any>
  ) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startTyping: (conversationKey: number) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markAsRead: (conversationKey: number) => Promise<any>
  clientMarkAsRead: (conversationKey: number) => void
  newConversation: () => Promise<any>
  updateConversationMessage: (conversationKey: number, abortController?: AbortController) => Promise<any>
  setIsFetching: (value: boolean) => void
  setIsOpenView: (value: boolean) => void
  clearMessagesConversation: () => void
  clearLastConversation: () => void
}

const initialState: State = {
  view: 'HOME',
  isOpenView: false,
  isConnected: false,
  isFetching: false,
  initialized: false,
  conversations: [],
  messages: [],
  pendingMessages: [],
  startingNewConversation: false,
  disableSendMessage: true,
  newMessageCount: 0,
  notifNewMessage: 0,
  setView: (view: VIEW_TYPE) => {},
  initializeSocket: () => {},
  getConversations: () => Promise.resolve({}),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendMessage: (conversationKey: number, text: string, files?: Array<any>) =>
    Promise.resolve({}),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startTyping: (conversationKey: number) => Promise.resolve({}),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  markAsRead: (conversationKey: number) => Promise.resolve({}),
  clientMarkAsRead: (conversationKey: number) => {},
  newConversation: () => Promise.resolve({}),
  updateConversationMessage: (conversationKey: number, abortController?: AbortController) => Promise.resolve({}),
  setIsFetching: (value: boolean) => {},
  setIsOpenView: (value: boolean) => {},
  clearMessagesConversation: () => {},
  clearLastConversation: () => {}
}

export const ChatContext = React.createContext<State>(initialState)
ChatContext.displayName = 'ChatContext'

type Action =
  | {
      type: 'CLIENT_INITIALIZE'
      socket: WebSocket
      conversations: Array<Conversation>
      conversation: Conversation | undefined
      messages: Array<Message>
    }
  | { type: 'CLIENT_STATE_ON_CHANGE'; value: boolean }
  | { type: 'CLIENT_SET_IS_FETCHING'; value: boolean }
  | { type: 'CLIENT_SET_IS_OPEN_VIEW'; value: boolean }
  | { type: 'CLIENT_SET_VIEW'; value: VIEW_TYPE }
  | { 
      type: 'CLIENT_TRANSFER_CONVERSATION'
      conversations: Array<Conversation>
      conversation: Conversation | undefined
    }
  | { type: 'SERVER_NEW_MESSAGE'; value: Message }
  | { type: 'SERVER_UPDATE_CONVERSATION'; value: Conversation }
  | {
      type: 'SERVER_UPDATE_MESSAGES_AND_CONVERSATIONS'
      messages: Array<Message>
      conversation: Conversation
    }
  | {
      type: 'CLIENT_CHANGE_CONVERSATION'
      messages: Array<Message>
      conversation: Conversation
    }
  | { type: 'CLIENT_NEW_CONVERSATION' }
  | { type: 'CLIENT_CLEAR_CONVERSATION' }
  | {
      type: 'CLIENT_MESSAGE_UPDATE'
      value: {
        localId: number
        conversationKey: number
        body: string
        attachments?: Array<any>
        status: 'sending' | 'failed'
      }
    }
  | {
      type: 'CLIENT_MARK_AS_READ'
      value: {
        conversationKey: number
      }
    }

function reducer(state: State, action: Action) {
  Log(action)
  switch (action.type) {
    case 'CLIENT_INITIALIZE': {
      let disableSendMessage = false
      if (action.conversation) {
        if (action.conversation.closed_at) {
          disableSendMessage = true
        } else if (action.conversation.last_message) {
          const content = action.conversation.last_message.interactive_body
          disableSendMessage = content?.disable_send_message
        }
      }

      const conversations = action.conversations.slice(0, 3)

      return {
        ...state,
        socket: action.socket,
        isConnected: true,
        initialized: true,
        conversations,
        conversation: action.conversation,
        newMessageCount: conversations.reduce((count, c) => count + c.new_count, 0),
        disableSendMessage,
        messages: uniqueMergeArray(state.messages, action.messages, 'id')
      }
    }
    case 'CLIENT_STATE_ON_CHANGE': {
      return { ...state, isConnected: action.value }
    }
    case 'CLIENT_SET_IS_FETCHING': {
      return { ...state, isFetching: action.value }
    }
    case 'CLIENT_SET_IS_OPEN_VIEW': {
      return { ...state, isOpenView: action.value }
    }
    case 'CLIENT_SET_VIEW': {
      return { ...state, view: action.value }
    }
    case 'CLIENT_TRANSFER_CONVERSATION': {

      const conversations = action.conversations.slice(0, 3)

      return {
        ...state,
        conversations: conversations,
        conversation: action.conversation,
        newMessageCount: conversations.reduce((count, c) => count + c.new_count, 0),
        lastMessage: action.conversation?.last_message
      }
    }
    case 'SERVER_NEW_MESSAGE': {
      const isCurrentConversation = state.conversation && state.conversation?.uid == action.value.conversation.uid
      
      let notifNewMessage = state.notifNewMessage
      if(
          !state.isOpenView || 
          (
            state.conversation && (document.hidden || !isCurrentConversation)
          )
        ){
        notifNewMessage++
      }

      if(!isCurrentConversation){
        return {
          ...state,
          notifNewMessage
        }
      }


      return {
        ...state,
        messages: uniqueMergeArray(state.messages, [action.value], 'id'),
        notifNewMessage,
        pendingMessages: state.pendingMessages.filter(
          (message) => message.body !== action.value.body
        )
      }
    }
    case 'SERVER_UPDATE_CONVERSATION': {
      // transfer conversation will change uid

      const isTransferringConversation = Boolean(action.value.transferring_conversation) || Boolean(action.value.previous_conversation)

      if (state.conversation && state.conversation?.uid !== action.value.uid) {
        if(isTransferringConversation){
          if(action.value.previous_conversation.uid !== state.conversation.uid){
            return state
          }
        } else {
          return state
        }
      }

      let disableSendMessage = false
      // handle message not update when user at home and conversation undefined
      let conversation = state.view == 'CHAT' ? action.value : state.conversation 
      let lastMessage = action.value?.last_message
      let conversations = [...state.conversations]

      if(isTransferringConversation){
        var index = _.findIndex(conversations, {uid: action.value.previous_conversation.uid});
        if(index >= 0){
          conversations[index] = action.value
        } else {
          conversations = _.unionBy([action.value], state.conversations, 'uid')
        }
      } else {
        conversations = _.unionBy([action.value], state.conversations, 'uid')
      }

      if(!action.value.transferred_to_conversation){
        if (action.value) {
          if (action.value.closed_at) {
            disableSendMessage = true
          } else if (action.value.last_message) {
            const content = action.value.last_message.interactive_body
            disableSendMessage = content?.disable_send_message
          }
        }
      }

      return {
        ...state,
        conversations: conversations.slice(0, 3),
        conversation,
        newMessageCount: conversations.slice(0, 3).reduce((count, c) => count + c.new_count, 0),
        disableSendMessage,
        lastMessage
      }
    }
    case 'SERVER_UPDATE_MESSAGES_AND_CONVERSATIONS': {
      let conversations = [...state.conversations]

      // check conversation coming is new or existing
      // const isExistConversation = conversations.some(some => some.uid == action.conversation.uid || some.uid == action.conversation.transferred_to_conversation?.uid)
      // if in chatView conversation & message not update
      const isDifferentConversation = state.view == 'CHAT' && (state.conversation && state.conversation?.uid !== action.conversation.uid)
      const newConversation = (isDifferentConversation || state.view == 'HOME') ? state.conversation : action.conversation
      
      // new message dont update if conversation uid is different
      const isUpdateMessages = (
                                  state.conversation == undefined || 
                                  (state.conversation && state.conversation?.uid == action.conversation.uid)
                              ) && 
                              state.view == 'CHAT'
      let newMessages = isUpdateMessages ? uniqueMergeArray(state.messages, action.messages, 'id') : state.messages
      // newMessages = (isDifferentConversation || state.view == 'HOME') ? state.messages : uniqueMergeArray(state.messages, action.messages, 'id')

      let disableSendMessage = false
      if (newConversation) {
        if (newConversation.closed_at) {
          disableSendMessage = true
        } else if (newConversation.last_message) {
          const content = newConversation.last_message.interactive_body
          disableSendMessage = content?.disable_send_message
        }
      }

      const isTransferedConversation = Boolean(action.conversation.transferred_to_conversation)

      if(isTransferedConversation){
        var index = _.findIndex(conversations, {uid: action.conversation.transferred_to_conversation.uid});
        if(index >= 0){
          conversations[index] = action.conversation
        } else {
          conversations = _.unionBy([action.conversation], state.conversations, 'uid')
        }
      } else {
        conversations = _.unionBy([action.conversation], state.conversations, 'uid')
      }

      return {
        ...state,
        // startingNewConversation: !isExistConversation ? false : state.startingNewConversation,
        // conversations: _.unionBy([newConversation || action.conversation], state.conversations, 'uid').slice(0, 3),
        // new conversation when opened chat view
        conversations: conversations.slice(0, 3),
        conversation: newConversation,
        newMessageCount: conversations.slice(0, 3).reduce((count, c) => count + c.new_count, 0),
        // newMessageCount: newConversation?.new_count || 0,
        disableSendMessage,
        messages: newMessages,
        pendingMessages: state.pendingMessages.filter(
          (pendingMessage) =>
            !action.messages.some(
              (message) => message.body === pendingMessage.body
            )
        )
      }
    }
    case 'CLIENT_CHANGE_CONVERSATION': {
      let disableSendMessage = false
      if (action.conversation) {
        if (action.conversation.closed_at) {
          disableSendMessage = true
        } else if (action.conversation.last_message) {
          const content = action.conversation.last_message.interactive_body
          disableSendMessage = content?.disable_send_message
        }
      }

      return {
        ...state,
        isFetching: false,
        conversation: action.conversation,
        newMessageCount: state.conversations.reduce((count, c) => count + c.new_count, 0),
        disableSendMessage,
        messages: action.messages,
        pendingMessages: state.pendingMessages.filter(
          (pendingMessage) =>
            !action.messages.some(
              (message) => message.body === pendingMessage.body
            )
        )
      }
    }
    case 'CLIENT_NEW_CONVERSATION': {
      return {
        ...state,
        isFetching: false,
        conversation: undefined,
        messages: [],
        pendingMessages: [],
        startingNewConversation: true
      }
    }
    case 'CLIENT_CLEAR_CONVERSATION': {
      return {
        ...state,
        isFetching: false,
        conversation: undefined,
        conversations: [],
        messages: [],
        pendingMessages: [],
        newMessageCount: 0,
        lastMessage: undefined
      }
    }
    case 'CLIENT_MESSAGE_UPDATE': {
      return {
        ...state,
        pendingMessages: _.values(
          _.merge(
            _.keyBy(state.pendingMessages, 'localId'),
            _.keyBy([action.value], 'localId')
          )
        )
      }
    }
    case 'CLIENT_MARK_AS_READ': {
      if(!state.conversation) return state

      const newConversations = [...state.conversations?.map(item => 
        item.uid == action.value.conversationKey ? 
        ({...item, new_count: 0}) : 
        item
      )]
      
      return {
        ...state,
        conversations: newConversations
      }
    }
  }
}

export const ChatProvider: React.FC = ({ ...props }): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { visitor_id, initialized: visitorInitialized } = useVisitor()
  const [reconnectingInterval, setReconnectingInterval] =
    useState<NodeJS.Timeout>()
  const [initializing, setInitializing] = useState<boolean>()
  const [countReconnecting, setCountReconnecting] = useState<number>(0)

  const getConversations = () => getConversationsApi().then(({ok, data}) => {
    if(ok && data){
      const conversation = 
        data && data.length > 0 ? data[0] : undefined
      dispatch({
        type: 'CLIENT_TRANSFER_CONVERSATION', 
        conversation, 
        conversations: data,
      })
    }
  })

  const sendMessage = (
    conversationKey: number,
    text: string,
    attachments?: Array<any>
  ) => {
    const localId = new Date().getTime()
    dispatch({
      type: 'CLIENT_MESSAGE_UPDATE',
      value: { localId, conversationKey, body: text, status: 'sending' }
    })
    return sendMessageApi({ conversationKey, text, attachments }).then(
      ({ ok, ...rest }) => {
        if (!ok)
          dispatch({
            type: 'CLIENT_MESSAGE_UPDATE',
            value: {
              localId,
              conversationKey,
              body: text,
              attachments,
              status: 'failed'
            }
          })

        return { ok, ...rest }
      }
    )
  }

  const startTyping = (conversationKey: number) =>
    startTypingApi(conversationKey)

  const markAsRead = (conversationKey: number) => markAsReadApi(conversationKey)

  const setView = (value: VIEW_TYPE) => dispatch({
    type: 'CLIENT_SET_VIEW',
    value
  })

  const clientMarkAsRead = (conversationKey: number) => {
    dispatch({
      type: 'CLIENT_MARK_AS_READ',
      value: {conversationKey}
    })
  }

  const newConversation = () =>
    newConversationApi().then(({ ok, ...rest }) => {
      ok && dispatch({ type: 'CLIENT_NEW_CONVERSATION' })

      return { ok, ...rest }
    })

  const updateConversationMessage = async (conversationKey: number, abortController?: AbortController) => {
    const conversation = state.conversations.filter(item => item.uid == conversationKey)
    let messages: Message[] = []
    if (conversation) {
      await getConversationMessagesApi({
        conversationKey: conversation[0].uid,
        signal: abortController?.signal
      }).then(
        ({ data }) => (messages = data?.messages || [])
        )

      dispatch({
        type: 'CLIENT_CHANGE_CONVERSATION',
        messages,
        conversation: conversation[0]
      })
    }
  }

  const setIsFetching = (value: boolean) => {
    dispatch({
      type: 'CLIENT_SET_IS_FETCHING',
      value
    })
  }

  const setIsOpenView = (value: boolean) => {
    dispatch({
      type: 'CLIENT_SET_IS_OPEN_VIEW',
      value
    })
  }

  const clearMessagesConversation = () => {
    dispatch({
      type: 'CLIENT_CLEAR_CONVERSATION'
    })
  }

  const clearLastConversation = () => {
    dispatch({
      type: 'CLIENT_NEW_CONVERSATION'
    })
  }

  useEffect(() => {
    if(state.conversations.length == 0) return

    let conversationTransfer = state.conversations.filter(item => item.transferred_to_conversation !== undefined)
    if(conversationTransfer.length > 0){
      let oldConversationKey = conversationTransfer[0].uid
      let newConversationKey = conversationTransfer[0].transferred_to_conversation?.uid

      getConversationDetailApi(newConversationKey).then(({ok, data}) => {
        if(ok){
          let newConversations = state.conversations
          let indexOldConversations = newConversations.findIndex(item => item.uid == oldConversationKey && item.transferred_to_conversation !== undefined)
          if (indexOldConversations !== -1) {
            newConversations[indexOldConversations] = data
          }
          
          dispatch({
            type: 'CLIENT_TRANSFER_CONVERSATION', 
            conversation: state.conversation?.uid == oldConversationKey ? data : state.conversation, 
            conversations: newConversations,
          })
        }
      })
      
    }
  }, [state.conversations])

  const initializeSocket = useCallback(async () => {
    if (!visitorInitialized || !visitor_id) return
    if (initializing) return

    setInitializing(true)
    const { data: conversations } = await getConversationsApi()
    const conversation = undefined
      // conversations && conversations.length > 0 ? conversations[0] : undefined
    let messages: Message[] = []
    // if (conversation) {
    //   await getConversationMessagesApi(conversation.uid).then(
    //     ({ data }) => (messages = data?.messages || [])
    //   )
    // }

    const url = `${Config.apiUrl}/ws/conversation/`
      .concat(`?visitor=${visitor_id}`)
      .concat(`&api_key=${Config.apiKey}`)
      .replace('http://', 'ws://')
      .replace('https://', 'wss://')

    const socket = new WebSocket(url)

    socket.onopen = () => {
      if (!state.isConnected) {
        setCountReconnecting(0)
        dispatch({
          type: 'CLIENT_INITIALIZE',
          socket,
          conversations,
          conversation,
          messages
        })
      }
    }
    socket.onclose = () =>
      dispatch({ type: 'CLIENT_STATE_ON_CHANGE', value: false })
    socket.onerror = (ev) => Log(ev, 'onerror')
    socket.onmessage = (ev) => {
      const { type, data } = JSON.parse(ev.data) as {
        type: string
        data: any
      }

      switch (type) {
        case 'new_message': {
          dispatch({ type: 'SERVER_NEW_MESSAGE', value: data })
          break
        }
        case 'update_conversation': {
          dispatch({ type: 'SERVER_UPDATE_CONVERSATION', value: data })
          break
        }
        case 'messages_updated': {
          dispatch({
            type: 'SERVER_UPDATE_MESSAGES_AND_CONVERSATIONS',
            messages: data.messages,
            conversation: data.conversation
          })
          break
        }
      }
    }

    setInitializing(false)
  }, [visitorInitialized, visitor_id])

  useEffect(() => {
    if (!visitorInitialized || !visitor_id) return
    if (state.socket && state.isConnected) {
      if (reconnectingInterval) {
        clearIntervalReconnecting()
      }
      return
    }

    if (!reconnectingInterval) {
      
      startIntervalConnection()
    }
  }, [state.isConnected, visitorInitialized, visitor_id])

  useEffect(() => {
    if(countReconnecting == 3){
      clearIntervalReconnecting()
      startIntervalConnection()
    }
  }, [countReconnecting])

  const startIntervalConnection = () => {
    setReconnectingInterval(
      setInterval(() => {
        Log(state.socket ? 'reconnecting' : 'connecting')
        setCountReconnecting( prev => prev + 1)
        initializeSocket()
      }, countReconnecting >= 3 ? 15000 : 5000)
    )
  }

  const clearIntervalReconnecting = () => {
    clearInterval(reconnectingInterval)
    setReconnectingInterval(undefined)
  }

  useEffect(() => {
    window.ononline =
      window.onfocus =
      window.onpageshow =
      document.onvisibilitychange =
        () => {
          if (!visitorInitialized || !visitor_id) return
          if (state.socket && state.isConnected) return
          Log('ononline/onfocus', 'initializeSocket')
          initializeSocket()
        }
    window.onoffline = () => {
      Log('onoffline')
      state.socket?.close(4000, 'Connection Lost')
    }
  }, [state.socket, state.isConnected, visitorInitialized, visitor_id])

  useEffect(() => {
    if (!visitorInitialized || !visitor_id) return
    // if (state.socket && state.isConnected) return
    initializeSocket()
  }, [visitorInitialized, visitor_id])

  useEffect(() => {
    Log(new Date())
    Log(state, 'chatContext')
  }, [state])

  const memoValue = useMemo(
    () => ({
      ...state,
      initializeSocket,
      getConversations,
      sendMessage,
      startTyping,
      markAsRead,
      setView,
      clientMarkAsRead,
      newConversation,
      updateConversationMessage,
      setIsFetching,
      setIsOpenView,
      clearMessagesConversation,
      clearLastConversation
    }),
    [state]
  )

  return <ChatContext.Provider value={memoValue} {...props} />
}

export const useChat = () => {
  const context = React.useContext(ChatContext)
  if (context === undefined) {
    throw new Error(`useChat must be used within a ChatProvider`)
  }
  return context
}
