import cn from 'clsx'
import moment from 'moment'
import React, { FC } from 'react'
import {
  Agent,
  getDisplayName,
  isImageByFileName,
  tryParseJSON,
  useApp,
  useLang,
  useVisitor
} from 'alpha-chat'
import { MdDescription } from 'react-icons/md'
import { FaUserAlt } from 'react-icons/fa'
import Text from '../Text'
import InputTypeOption from '../ChatBot/InputTypeOption'
import InputTypeForm from '../ChatBot/InputTypeForm'
import InputTypeButton from '../ChatBot/InputTypeButton'
import formatValue from '../../utils/formatValue'

type ChatBodyProps = {
  key?: any,
  isSelf?: boolean
  groupWithNext?: boolean
  groupWithPrevious?: boolean
  showInput?: boolean
  attachments?: Array<any>
  agent?: Agent
  events?: Array<any>
  onClick?: (text: string) => void
  // chat props
  body?: any
  sentAt?: Date
  status?: string,
  bubbleBackgroundColor?: string,
  bubbleTextColor?: string
}

type RenderBubbleProps = {
  children: React.ReactNode | any,
  isShowAvatar?: boolean,
  renderAvatar?: React.ReactNode | any | undefined,
  isSelf?: boolean,
  maxWidthBubble?: string
}

const RenderBubble: FC<RenderBubbleProps> = ({children, isShowAvatar, renderAvatar, isSelf, maxWidthBubble}) => {
  if(isShowAvatar){
    return (
      <div className='w-full flex flex-row overflow-x-hidden'>
        {!isSelf && renderAvatar && renderAvatar}
        <div className={cn(
          "flex flex-col w-full",
          {
            'items-end': isSelf
          }
          )}>
          {children}
        </div>
        {isSelf && renderAvatar && renderAvatar}
      </div>
    )
  } else {
    return (<>
      {children}
    </>)
  }
}

const ChatBody: React.FC<ChatBodyProps> = ({
  key,
  isSelf,
  groupWithPrevious,
  groupWithNext,
  showInput,
  attachments,
  agent,
  events,
  children,
  onClick,
  // chat props
  body,
  sentAt,
  status = 'pending',
  bubbleBackgroundColor,
  bubbleTextColor
}) => {
  const { app_settings } = useApp()
  const { business } = useVisitor()
  const { lang } = useLang()
  const content = typeof body === 'string' ? tryParseJSON(body) : body
  const text = content ? (content?.text || content?.body) : body
  const dates = events?.filter((event) => event.type === 'date')
  const date = dates && dates.length > 0 ? dates[dates.length - 1] : undefined
  const isShowAvatar = app_settings?.['chat-widget']?.show_avatar_in_message
  const maxWidthBubble = isShowAvatar ? '83%' : '75%'
  const cornerRadius = app_settings?.['chat-widget']?.ui?.corner_radius

  const renderAvatarMessage = () => {
    if(isShowAvatar){
      // if(!isSelf){
        if(!groupWithNext){
          if(isSelf){
            return (
              <div className="flex justify-center items-center bg-slate-200 text-slate-400 h-8 aspect-square rounded-full self-end my-1 ml-2 mb-4">
                <FaUserAlt />
              </div>
            )
          } else {
            return <img 
                    src={
                      app_settings?.['chat-widget']?.default_operator_avatar ||
                      business?.avatar?.image_url
                    } 
                    className="h-8 aspect-square rounded-full self-end my-1 mr-2 mb-4"
                  />
          }
        } else {
          return <div className="h-8 aspect-square rounded-full self-end my-1 mr-2"></div>
        }
      // }
    }

    return <></>
  }

  const displayNameAgent = () => {
    if(app_settings?.['chat-widget']?.default_agent_name) return app_settings?.['chat-widget']?.default_agent_name

    return getDisplayName(agent, 'Agent')
  }

  return (
    <div
      key={key}
      className={cn('flex flex-col px-2', {
        'items-end': isSelf,
        'items-start': !isSelf,
        'mb-2': !groupWithNext,
        'mb-1': groupWithNext,
        'animation-move-up': !isSelf || !text || status == 'sending'
      })}
    >
      
      {events && events.length > 0 && date && (
        <div className='flex flex-col w-full mb-2 space-y-2'>
          <Text
            key={date.id}
            size='none'
            className={cn(
              'text-xs self-center text-center',
              'bg-slate-200 rounded p-1'
            )}
          >
            {date.text}
          </Text>
        </div>
      )}
      
      <RenderBubble isShowAvatar={isShowAvatar} isSelf={isSelf} renderAvatar={renderAvatarMessage()} maxWidthBubble={maxWidthBubble}>
        <div
          className={cn('flex flex-col p-2', {
            'bg-emerald-200': isSelf,
            'bg-slate-200': !isSelf
          })}
          style={{
            maxWidth: isShowAvatar ? '85%' : '75%', 
            backgroundColor: bubbleBackgroundColor || (isSelf ? 'rgb(167 243 208)' : 'rgb(226 232 240)'),
            color: bubbleTextColor || '#000000',
            borderTopLeftRadius: !groupWithPrevious ? 
                                  `${cornerRadius || 12}px` : 
                                  '',
            borderTopRightRadius: !groupWithPrevious ? 
                                  `${cornerRadius || 12}px` : 
                                  '',
            borderBottomLeftRadius: (isSelf && !groupWithNext) ? 
                                  `${cornerRadius || 12}px` : 
                                  '',
            borderBottomRightRadius: (!isSelf && !groupWithNext) ? 
                                  `${cornerRadius || 12}px` : 
                                  '',
          }}
        >
          { 
            !app_settings?.['chat-widget']?.hide_agent_name &&
            !groupWithPrevious && (
              <Text size='none' className='font-bold text-sm'>
                {isSelf ? 'You' : displayNameAgent()}
              </Text>
            )
          }
          {(text || children) && (
            <Text size='none' className='break-words text-sm' style={{overflowWrap: 'anywhere'}}>
              {text || children}
            </Text>
          )}

          {attachments && attachments.length > 0 && (
            <div
              className={cn('grid grid-cols-2 gap-2 mt-2', {
                'grid-cols-1': attachments.length === 1,
                'grid-cols-2': attachments.length > 1
              })}
            >
              {attachments.map((attachment, i) => {
                if (isImageByFileName(attachment.file_name))
                  return (
                    <img
                      key={i}
                      src={attachment.image_url}
                      className='cursor-pointer'
                      onClick={() => window.open(attachment.image_url)}
                    />
                  )

                return (
                  <Text
                    key={i}
                    size='none'
                    className={cn(
                      'text-sm cursor-pointer flex items-center p-1 hover:bg-slate-300',
                      'border border-dashed border-slate-400'
                    )}
                    onClick={() => window.open(attachment.image_url)}
                  >
                    <MdDescription className='mr-1' size={16} />
                    {attachment.file_name}
                  </Text>
                )
              })}
            </div>
          )}
        </div>

        {!groupWithNext && (
          <Text 
            size='none' 
            className='text-xs animation-in'
          >
            {sentAt && moment(sentAt).format('HH:mm')}
            {isSelf && sentAt && ' . '}
            {isSelf && `${lang[`${status}`]}`}
          </Text>
        )}
      </RenderBubble>

      {events && 
        events.length > 0 && 
        events.filter((event) => event.type !== 'date').length > 0 && (
        <div className='flex flex-col w-full mt-2 space-y-2'>
          {events
            .filter((event) => event.type !== 'date')
            .map((event) => (
              <Text
                key={event.id}
                size='none'
                className={cn('text-xs self-center text-center', {
                  block: event.type === 'event',
                  'bg-slate-200 rounded p-1': event.type !== 'event'
                })}
              >
                {event.text}
              </Text>
            ))}
        </div>
      )}

      {showInput && (
        <>
          <Text size='sm' className='break-words animation-move-up'>
            {content?.helper_text}
          </Text>
          {content?.input_type === 'button' && (
            <div className={cn('flex flex-wrap w-full justify-end animation-move-up mt-2')}>
              <InputTypeButton options={content?.options} onClick={onClick} />
            </div>
          )}
          {content?.input_type === 'form' && (
            <InputTypeForm className='animation-move-up' fields={content?.form_fields} onClick={onClick} />
          )}
          {content?.input_type === 'option' && (
            <InputTypeOption className='animation-move-up' options={content?.options} onClick={onClick} />
          )}
        </>
      )}
    </div>
  )
}

export default ChatBody
