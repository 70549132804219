import cn from 'clsx'
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { ChatConfig, useApp, useChat, useVisitor } from 'alpha-chat'
import { ChatView, HomeView, RegisterView } from './components'
import WidgetButton from './components/WidgetButton'
import { DEVICE_TYPE, VIEW_TYPE } from './utils/constant'
import { omit, unset } from 'lodash'
import Text from './components/Text'
import WebFont from "webfontloader";
import LoginView from './components/Home/LoginView'
import Log from './utils/log'

const LIST_VIEW_TYPE = { 
  HOME: 'HOME', 
  CHAT: 'CHAT',
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN'
}
const buttonSize = 100
const widthButtonSizeWithSideNotif = 280
const spaceBetweenButtonAndChat = 16
const extraSpaceOnTopOfChat = 40
const chatWidth = 376
const maxChatHeight = 590

const Widget: React.FC<{
  visibleOnLoad?: boolean
  initialScreen: { height: number; width: number }
}> = ({ visibleOnLoad, initialScreen }) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>
  const [isOpenView, setIsOpenView] = useState<boolean>(false)
  const [isOpenViewDelay, setIsOpenViewDelay] = useState<boolean>(false)
  const [isShowButton, setIsShowButton] = useState(visibleOnLoad)
  const [currentView, setCurrentView] = useState(LIST_VIEW_TYPE.HOME)
  const [currentConversationKey, setCurrentConversationKey] = useState(undefined)
  const [deviceType, setDeviceType] = useState<DEVICE_TYPE>('DESKTOP')
  const [screen, setScreen] = useState(initialScreen)
  const isShowPowered = currentView !== LIST_VIEW_TYPE.CHAT 
  const [isLoading, setIsLoading] = useState(false)
  const [notifSound, setNotifSound] = useState<HTMLAudioElement>();
  const [isShowSideNotif, setIsShowSideNotif] = useState<boolean>(false)

  const { initialized: appInitialized, initializedOnLogin, app_settings, setInitOnLogin } = useApp()
  const { 
    _goid, 
    register, 
    logout, 
    logEvent, 
    initializingVisitor,
    initialized: visitorInitialized, 
    countReconnecting: visitorCountReconnecting, 
    visitor_data 
  } = useVisitor()
  const { 
    clearMessagesConversation, 
    newMessageCount, 
    notifNewMessage,
    initialized: chatInitialized,
    getConversations,
    clearLastConversation,
    setIsOpenView: setIsOpenViewContex
  } = useChat()

  const isLauncherMessage = Boolean(app_settings?.['chat-widget']?.launcher_message)

  useEffect(() => {
    if (screen.width > 500) {
      if (deviceType !== 'DESKTOP') setDeviceType('DESKTOP')
    } else if (screen.width <= 500) {
      if (deviceType !== 'MOBILE') setDeviceType('MOBILE')
    }

    setNotifSound(new Audio(require('./assets/sounds/alert-msg.mp3')))
  }, [])

  useEffect(() => {
    if(!app_settings) return 

    if(app_settings['chat-widget'].google_font){
      initializeFont([app_settings['chat-widget'].google_font])
    }

    if(app_settings?.['chat-widget']?.launcher_message){
      const root = document.getElementsByClassName('root-chat-widget')
      root?.[0]?.classList.add('root-side-notif')

      window.top?.postMessage(
        {
          screen: {
            height: 100,
            width: widthButtonSizeWithSideNotif
          }
        },
        '*'
      )

      setTimeout(() => {
        setIsShowSideNotif(true)
      }, 900);

      handleResizeLauncherMesage()
    }
  }, [app_settings])

  const toggleChat = useCallback(
    (value?: boolean) => {
      const handleOpenView = () => {
        if(value || isOpenView){
          // add delay if close
          setIsOpenViewDelay(false)
          setTimeout(() => {
            setIsOpenView(typeof value !== 'undefined' ? value : !isOpenView)
          }, 250);
        } else {
          setIsOpenView(typeof value !== 'undefined' ? value : !isOpenView)
        }
      }

      if(!app_settings?.['chat-widget']?.initialize_on_load){
        if(!visitorInitialized){
          initializingVisitor()
          handleOpenView()
          return
        } else if(visitorInitialized && (ChatConfig._goid && ChatConfig.visitor_id && ChatConfig.has_conversation)){
          handleOpenView()
        }
      }

      if (chatInitialized && visitorInitialized) {
        handleOpenView()
      }
    },
    [app_settings, chatInitialized, visitorInitialized, isOpenView]
  )

  const toggleButton = useCallback(
    (value?: boolean) => {
      setIsShowButton(typeof value !== 'undefined' ? value : !isShowButton)
    },
    [isShowButton]
  )

  const onLogin = useCallback(
    (value?: boolean) => {
      setInitOnLogin(value)
    },
    [initializedOnLogin]
  )

  const toggleCurrentView = useCallback(
    (value: VIEW_TYPE, conversationKey?: any) => {
      setCurrentView(value)
      
      if(conversationKey){
        setCurrentConversationKey(conversationKey)
      } else {
        setCurrentConversationKey(undefined)
      }

      if (value == 'HOME') {
        clearLastConversation()
      }
    },
    [currentView],
  )

  const initializeFont = (fonts: string[]) => {
    WebFont.load({
      google: {
        families: fonts
      }
    });

    const body = document.querySelector('body');
    const fontFamily = fonts[0].split(':')
    if(body){
      body.style.fontFamily = fontFamily?.[0]?.replace(/[+]/g,' ');
    }
  }

  const registerUser = (contact: any) => {
    if(!_goid) return

    register(_goid, contact).then(({ok, data}) => {
      if(ok){
        clearMessagesConversation()
        getConversations()
      }
    })
  }

  // iFrame Support
  window.onmessage = useCallback(
    (e) => {
      if (e.data.type !== 'alpha-chat') return

      switch (e.data.action) {
        case 'screen':
          setScreen(e.data.screen)
          break
        case 'register':
          if(_goid){
            // localStorage.setItem('goapp-chat-widget-session-register', JSON.stringify(e.data.data?.contact))
            if(Object.keys(visitor_data).length > 0){
              logout(_goid, e.data.data?.contact)
            } else {
              registerUser(e.data.data?.contact)
            }
          } else {
            initializingVisitor(e.data.data?.contact)
            // console.log('need initialize goappChatWidget')
          }
          break
        case 'logout': 
          if(_goid){
            logout(_goid).then(({ok}) => {
              if(ok){
                clearMessagesConversation()
                window.top?.postMessage(
                  {
                     removeSessionKey: {
                        key: 'has_conversation'
                     }
                  },
                  '*'
                )
              }
            })
          } else {
            Log('need initialize goappChatWidget')
          }
          break
        case 'logEvent':
          logEvent(e.data.name, e.data.data)
          break
        case 'toggleChat':
          toggleChat(e.data.value)
          break
        case 'toggleButton':
          toggleButton(e.data.value)
          break
        case 'onLogin':
          onLogin(e.data.value)
          break
        default:
          break
      }
    },
    [toggleChat, toggleButton, onLogin, logEvent, setScreen]
  )

  useEffect(() => {
    if(!appInitialized) return 

    if (isOpenView) {
      setIsShowSideNotif(false)
      window.top?.postMessage(
        {
          screen: {
            height: deviceType === 'MOBILE' ? 
                      screen.height : 
                      isLauncherMessage ? 
                        ref.current.scrollHeight :
                        ref.current.scrollHeight + spaceBetweenButtonAndChat,
            width: deviceType === 'MOBILE' ? screen.width : chatWidth
          },
          isOpenView
        },
        '*'
      )
    } else {
      if(isLauncherMessage){
        setIsShowSideNotif(true)
      } 

      const widthButtonSize = isLauncherMessage ? widthButtonSizeWithSideNotif : buttonSize
      window.top?.postMessage(
        {
          screen: {
            height: deviceType === 'MOBILE' ? buttonSize : buttonSize,
            width: deviceType === 'MOBILE' ? widthButtonSize : widthButtonSize
          },
          isOpenView
        },
        '*'
      )
    }

    setIsOpenViewContex(isOpenView)

    if(isOpenView){
      setTimeout(() => {
        setIsOpenViewDelay(true)
      }, 150);
    }
  }, [isOpenView])

  useEffect(() => {
    if(!appInitialized) return

    if (screen.width > 500) {
      if (deviceType !== 'DESKTOP') setDeviceType('DESKTOP')
      const widthButtonSize = isLauncherMessage ? widthButtonSizeWithSideNotif : buttonSize

      window.top?.postMessage(
        {
          screen: {
            height: isOpenView ? ref.current.scrollHeight : buttonSize,
            width: isOpenView ? chatWidth : widthButtonSize
          }
        },
        '*'
      )
    } else if (screen.width <= 500) {
      if (deviceType !== 'MOBILE') setDeviceType('MOBILE')
      const widthButtonSize = isLauncherMessage ? widthButtonSizeWithSideNotif : buttonSize
      
      window.top?.postMessage(
        {
          screen: {
            height: isOpenView ? ref.current.scrollHeight : buttonSize,
            width: isOpenView ? screen.width : widthButtonSize
          }
        },
        '*'
      )
    }
  }, [deviceType, screen])

  useEffect(() => {
    handleResizeLauncherMesage()
  }, [deviceType])

  const handleResizeLauncherMesage = () =>{
    if(!isLauncherMessage) return 
    
    const root = document.getElementsByClassName('root-chat-widget')
    
    if (screen.width > 500) {
      root?.[0]?.classList.remove('root-side-notif-mobile')
      root?.[0]?.classList.add('root-side-notif-desktop')
    } else if (screen.width <= 500) {
      root?.[0]?.classList.remove('root-side-notif-desktop')
      root?.[0]?.classList.add('root-side-notif-mobile')
    }
  }

  useEffect(() => {
    if (chatInitialized && visitorInitialized && appInitialized) {
      window.top?.postMessage(
        {
          config: omit(ChatConfig, [
            'apiKey',
            'apiUrl',
            'isProduction',
            'enableLog',
            'visibleOnLoad',
            'contact'
          ])
        },
        '*'
      )
    }
    setCurrentView(LIST_VIEW_TYPE.HOME)
  }, [chatInitialized, visitorInitialized, appInitialized])

  useEffect(() => {
    if(visitor_data !== null && visitor_data !== undefined && Object.keys(visitor_data).length > 0 ){
      window.top?.postMessage(
        {
          visitor_data: visitor_data
        },
        '*'
      )
    }
  }, [visitor_data])

  useEffect(() => {
    window.top?.postMessage({
      newMessageCount
    }, '*')
  }, [newMessageCount])

  useEffect(() => {
    if(notifNewMessage !== 0 && notifSound && !ChatConfig.disableNotifSound) {
      notifSound.play()
    }
  }, [notifNewMessage, ChatConfig])
  
  

  const isLoadingWidgetButton = () => {
    if(!app_settings?.['chat-widget']?.initialize_on_load){
      return !appInitialized && app_settings?.['chat-widget']?.initialize_on_load
    } 

    return !appInitialized || !chatInitialized || !visitorInitialized
  }

  const widgetDesktopOpen = screen.height -
                            // buttonSize -
                            // spaceBetweenButtonAndChat -
                            extraSpaceOnTopOfChat

  return visitorCountReconnecting < 2 && appInitialized ? (
    <>
      {
        isShowSideNotif &&
        <div 
          id="side-notif" 
          className={cn(
            'animation-move-right',
            'truncate w-full',
            {
              'mb-[18px]': deviceType == 'MOBILE'
            }
          )}
          style={{
            backgroundColor: app_settings?.['chat-widget'].ui.primary_color,
            color: 'white',
            borderTopLeftRadius: `${10}px`,
            borderBottomLeftRadius: `${10}px`,
            fontWeight: 600
          }}
        >
          <Text classNameString='truncate text-white'>{ app_settings?.['chat-widget'].launcher_message }</Text>
        </div>
      }
      <div
        className={cn('flex flex-col relative justify-end')}
        style={{
          height: 
            deviceType == 'MOBILE' && isOpenView ? 
            screen.height : 
              deviceType === 'DESKTOP' && isOpenView ? 
              widgetDesktopOpen :
                '100%',
          width: 
            deviceType == 'MOBILE' && isOpenView ? 
            screen.width : 
            ''
          // paddingRight: !isOpenView && deviceType === 'MOBILE' ? 20 : 0,
          // paddingBottom: !isOpenView && deviceType === 'MOBILE' ? 20 : 0
        }}
        ref={ref}
      >
        {isOpenViewDelay && (
          <div
            className={cn('flex flex-col bg-white self-center relative', 
            'animation-slide-top',
            {
              'box-shadow-main my-4': deviceType === 'DESKTOP'
            })}
            style={{
              height:
                '100%',
                // deviceType === 'DESKTOP'
                //   ? screen.height -
                //     buttonSize -
                //     spaceBetweenButtonAndChat -
                //     extraSpaceOnTopOfChat
                //   : '100%',
              width: deviceType === 'DESKTOP' ? chatWidth - 16 : '100%',
              // maxHeight: deviceType === 'DESKTOP' ? maxChatHeight + 'px' : 'unset',
              // animation: isOpenView ? 'inAnimation 280ms ease-in' : 'outAnimation 280ms ease-out',
              // animationFillMode: !isOpenView ? 'forwards' : ''
              maxHeight: deviceType === 'DESKTOP' ? maxChatHeight + 'px' : 'unset',
              borderRadius: `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px`
            }}
          >
            {isShowPowered && !app_settings?.['chat-widget'].hide_goapp_label && (
              <div className="absolute bottom-0 w-full text-center p-6">
                <Text size="base" className="w-full text-gray-300">Powered by Goapp</Text>
              </div>
            )}
            {currentView === LIST_VIEW_TYPE.CHAT && (
              <ChatView
                conversationKey={currentConversationKey}
                isOpen={isOpenView}
                onToggle={() => toggleChat()}
                onToggleCurrentView={(value) => toggleCurrentView(value)}
                deviceType={deviceType}
                showCloseButton={!isShowButton || deviceType === 'MOBILE'}
              />
            )}
            {currentView === LIST_VIEW_TYPE.HOME && (
              <HomeView 
                onToggle={() => toggleChat()}
                deviceType={deviceType}
                onToggleCurrentView={(value: VIEW_TYPE, conversationKey?: any) => toggleCurrentView(value, conversationKey)}
              />
            )}
            {currentView === LIST_VIEW_TYPE.REGISTER && (
              <RegisterView
                onToggle={() => toggleChat()}
                deviceType={deviceType}
                onToggleCurrentView={(value: VIEW_TYPE, conversationKey?: any) => toggleCurrentView(value, conversationKey)}
              />
            )}
            {currentView === LIST_VIEW_TYPE.LOGIN && (
              <LoginView
                onToggle={() => toggleChat()}
                deviceType={deviceType}
                onToggleCurrentView={(value: VIEW_TYPE, conversationKey?: any) => toggleCurrentView(value, conversationKey)}
              />
            )}
          </div>
        )}
        {isShowButton &&
          ((deviceType === 'MOBILE' && !isOpenView) ||
            deviceType === 'DESKTOP') && (
            <WidgetButton
              deviceType={deviceType}
              isOpen={isOpenView}
              newMessageCount={newMessageCount}
              loading={isLoadingWidgetButton()}
              onClick={() => toggleChat()}
              onMouseEnter={() => {
                if(!isLauncherMessage) return 

                setIsShowSideNotif(false)
              }}
              onMouseLeave={() => {
                if(!isLauncherMessage || isOpenView) return 

                Log('setIsShowSideNotif onmouse')
                setIsShowSideNotif(true)
              }}
            />
          )}
      </div>
    </>
  ) : <></>
}

export default Widget
