import cn from 'clsx'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { MdClose, MdMoreVert, MdOutlineArrowBackIosNew } from 'react-icons/md'
import { useApp, useChat, useVisitor } from 'alpha-chat'
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant'
import Text from '../Text'

const chatWidth = 376

type HomeHeaderProps = {
  isConnected?: boolean
  showCloseButton?: boolean
  showBackButton?: boolean
  onToggle?: () => void
  onToggleCurrentView?: (value: VIEW_TYPE) => void
  deviceType?: DEVICE_TYPE,
  primaryColor?: string
}

const HomeHeader: React.FC<HomeHeaderProps> = ({
  isConnected,
  showCloseButton,
  showBackButton,
  onToggle,
  onToggleCurrentView,
  deviceType,
  primaryColor
}) => {
  const { app_settings } = useApp()
  const { business } = useVisitor()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>()
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  const iconHeader = app_settings?.['chat-widget']?.ui?.header_icon || business?.avatar?.image_url

  useEffect(() => {
    const handleClickOutside = (ev: any) => {
      if (ref.current && !ref.current.contains(ev.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside, true)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [])

  return (
    <div
      className={cn(
         'flex flex-row justify-between p-4 space-x-4 z-[2]',
         'items-center'
      )}
      style={{
        backgroundColor: primaryColor,
        borderRadius: deviceType === 'DESKTOP' ? `${app_settings?.['chat-widget']?.ui?.corner_radius || 8}px` : ''
      }}
    >
       <div className="flex items-center gap-x-3">
        {
          showBackButton && (
            <div 
              className='cursor-pointer' 
              onClick={() => {
                onToggleCurrentView && onToggleCurrentView('HOME')
              }}>
              <MdOutlineArrowBackIosNew className='text-slate-200' size={23} />
            </div>
          )
        }
         {iconHeader ? (
            <img
               src={iconHeader}
               className='h-10 aspect-square rounded-full'
            />
         ) : (
            <div className='h-10 aspect-square bg-slate-200 rounded-full' />
         )}
        <Text className={cn('font-bold text-white truncate')} classNameString="truncate" style={{maxWidth: deviceType == 'DESKTOP'? chatWidth - 146 : chatWidth - 91}}>
            {app_settings?.['chat-widget']?.default_operator_name || business?.name || ' '}
        </Text>
       </div>
      <div className='relative' ref={ref}>
        <MdClose 
          className='cursor-pointer text-slate-200'
          onClick={onToggle}
          size={28} />
      </div>
    </div>
  )
}

export default HomeHeader
