import cn from 'clsx'
import { FC } from 'react'
import {
  RiInstagramFill as InstagramIcon,
  RiFacebookFill as FacebookIcon,
  RiTwitterFill as TwitterIcon,
  RiWhatsappFill as WhatsappIcon,
  RiMailFill as MailIcon,
  RiPhoneFill as PhoneIcon,
  RiLinkedinFill as LinkedinIcon
} from 'react-icons/ri'
import { useApp, useChat } from 'alpha-chat'

const InputTypeButton: FC<{
  options: []
  onClick?: (text: string) => void
}> = ({ options, onClick }) => {
  const { isConnected } = useChat()
  const { app_settings } = useApp()
  return (
    <>
      {options?.map((option: any) => (
        <button
          key={option.value}
          className={cn(
            'p-2 mb-2 mr-2 border border-solid text-black rounded',
            'text-xs sm:text-xs md:text-sm lg:text-sm ',
            // Align icon and text
            'flex flex-row items-center space-x-1',
            {
              'border-[rgb(4, 151, 76)] hover:border-green-800' : app_settings?.['chat-widget']?.ui?.primary_color == undefined
            }
          )}
          style={{
            backgroundColor: app_settings?.['chat-widget']?.ui?.primary_color || 'rgb(4, 151, 76)',
            color: 'white'
          }}
          onClick={() => {
            if (option.link_to) window.open(option.link_to, option.target || '_blank')
            
            onClick && onClick(JSON.stringify(option))
          }}
          disabled={!isConnected}
        >
          {option.link_to && option.link_to.startsWith('https://wa.me') && (
            <WhatsappIcon style={{ fontSize: '1.4em', color: '#4FCE5D' }} />
          )}
          {option.link_to && option.link_to.startsWith('mailto://') && (
            <MailIcon style={{ fontSize: '1.4em', color: '#4285F4' }} />
          )}
          {option.link_to &&
            option.link_to.startsWith('https://instagram.com') && (
              <InstagramIcon style={{ fontSize: '1.4em', color: '#DD2A7B' }} />
            )}
          {option.link_to &&
            option.link_to.startsWith('https://facebook.com') && (
              <FacebookIcon style={{ fontSize: '1.4em', color: '#3B5998' }} />
            )}
          {option.link_to &&
            option.link_to.startsWith('https://twitter.com') && (
              <TwitterIcon style={{ fontSize: '1.4em', color: '#08A0E9' }} />
            )}
          {option.link_to && option.link_to.startsWith('https://linkedin') && (
            <LinkedinIcon style={{ fontSize: '1.4em', color: '#0077B5' }} />
          )}
          {option.link_to && option.link_to.startsWith('tel://') && (
            <PhoneIcon style={{ fontSize: '1.4em', color: '#00AA00' }} />
          )}
          <span>{option.text}</span>
        </button>
      ))}
    </>
  )
}

export default InputTypeButton
