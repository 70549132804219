import { FC, useState } from 'react'
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant'
import Text from '../Text'
import { useChat, useVisitor, useApp } from 'alpha-chat';
import Container from './Container';
import cn from 'clsx'

const LoginView: FC<{
  deviceType?: DEVICE_TYPE
  onToggle?: () => void
  onToggleCurrentView?: (value: VIEW_TYPE, conversationKey?: any) => void
}> = ({deviceType, onToggle, onToggleCurrentView}) => {

   const { app_settings, initializedOnLogin } = useApp()

   return (
      <Container
         deviceType={deviceType}
         onToggle={onToggle}
         onToggleCurrentView={onToggleCurrentView}
         intro={false}
         showBackButton>
         <div className="m-5 py-5 rounded-lg bg-white" style={{boxShadow: "0px 6px 11px 0px rgb(159 159 159 / 45%)"}}>
            <Text className="text-md p-5">{app_settings?.login_form.title || ''}</Text>
            {
               app_settings?.login_form?.login_url ? (
                  <div className='space-y-2 w-full border-slate-200 bg-white p-5 pt-0 rounded-none border-0 shadow-none'>
                     <a href={app_settings?.login_form?.login_url} target="_blank" rel="noreferrer" className="">
                        <button
                           className={cn(
                              'py-2 w-full border border-solid bg-gray-600 disabled:bg-gray-300 rounded-lg',
                              'text-xs sm:text-xs md:text-sm lg:text-sm outline-none',
                              'text-white uppercase',
                              '!mt-6'
                           )}
                           style={{
                              backgroundColor: app_settings?.['chat-widget']?.ui?.primary_color ? 
                              app_settings?.['chat-widget']?.ui?.primary_color : 'rgb(148 163 184)'}}
                        >
                              Login
                        </button>
                     </a>
                  </div>
               ) : initializedOnLogin ? (
                  <div className='space-y-2 w-full border-slate-200 bg-white p-5 pt-0 rounded-none border-0 shadow-none'>
                     <button
                        className={cn(
                           'py-2 w-full border border-solid bg-gray-600 disabled:bg-gray-300 rounded-lg',
                           'text-xs sm:text-xs md:text-sm lg:text-sm outline-none',
                           'text-white uppercase',
                           '!mt-6'
                        )}
                        style={{
                           backgroundColor: app_settings?.['chat-widget']?.ui?.primary_color ? 
                           app_settings?.['chat-widget']?.ui?.primary_color : 'rgb(148 163 184)'}}
                        onClick={() => {
                           window.top?.postMessage({
                              onLogin: ''
                           }, '*')
                           // eval(app_settings?.login_form?.login_script)
                        }}
                     >
                           Login
                     </button>
                  </div>
               ) : <></>
            }
         </div>
      </Container>
   )
}

export default LoginView