import { Business } from "./lib/types"

export type ConfigType = {
  apiUrl?: string
  apiKey?: string | null
  isProduction: boolean
  enableLog: boolean
  //
  visitor_id?: string | null
  _goid?: string | null
  token?: string | null
  business_uid?: string | null
  contact?: any | null
  has_conversation?: boolean

  disableNotifTitle?: boolean
  disableNotifSound?: boolean
}

const Config: ConfigType = {
  isProduction: false,
  enableLog: true
}

export const InitConfig = (config: {
  apiUrl: string
  apiKey: string
  isProduction?: boolean
  enableLog?: boolean
  visitor_id?: string | null
  _goid?: string | null
  token?: string | null
  business_uid?: Business | null
  contact?: any | null
}) => {
  Object.assign(Config, { ...config })
}

export const SetConfig = (config: {
  visitor_id?: string | null
  _goid?: string | null
  token?: string | null
  business?: Business | null
  contact?: any | null
}) => {
  Object.assign(Config, { ...config })
}

export default Config
