import cn from 'clsx'
import { FC, useState } from 'react'
import Input from '../Input'
import LoadingDots from '../LoadingDots'
import RadioButton from '../RadioButton'
import Text from '../Text'
import { useApp, useChat } from 'alpha-chat'

type Field = {
  field_name: string
  input_type: 'text' | 'option' | 'mobile_no' | 'email'
  label: string
  required: '1' | '0'
  validation?: 'email'
  options?: Array<any>
}

const InputTypeForm: FC<{
  fields: Field[]
  onClick?: (text: string) => void
  className?: string
  titleButton?: string,
  clasNameButton?: string,
  isLoading?: boolean
}> = ({ fields, onClick, className, titleButton = 'Submit', clasNameButton, isLoading }) => {
  const { isConnected } = useChat()
  const { app_settings } = useApp()
  const [formData, setFormData] = useState<any>(
    fields.reduce((prev, curr) => ({ ...prev, [curr.field_name]: '' }), {})
  )

  const getTextFormat = () =>
    Object.keys(formData).reduce((prev, curr) => {
      const field = fields.find((field) => field.field_name === curr)!
      let result = ''

      if (field.input_type === 'text' || field.input_type === 'mobile_no' || field.input_type === 'email') {
        result = `${field.label}: ${formData[curr]}`
      } else if (field.input_type === 'option') {
        const option = field.options!.find(
          (option) => option.value === formData[curr]
        )
        result = `${field.label}: ${option.text}`
      }

      if (prev === '') return result

      return `${prev}, ${result}`
    }, '')

  return (
    <form
      className={cn('space-y-2 w-full p-2 border border-slate-200 rounded-lg shadow', className)}
      onSubmit={(ev) => {
        ev.preventDefault()
        onClick &&
          onClick(
            JSON.stringify({ text: getTextFormat(), form_values: formData })
          )
      }}
    >
      {fields
        .filter((field) => field.input_type === 'text' || field.input_type === 'mobile_no' || field.input_type === 'email')
        .map((field) => (
          <Input
            key={field.field_name}
            name={field.field_name}
            type={field.input_type === 'mobile_no' ? 'number' : field.input_type}
            validation={field.validation}
            required={field.required === '1'}
            disabled={isLoading}
            onChange={(value) =>
              setFormData({
                ...formData,
                [field.field_name]: value
              })
            }
          >
            {field.label}
          </Input>
        ))}

      {fields
        .filter(
          (field) =>
            field.input_type === 'option' &&
            field.options &&
            field.options.length > 0
        )
        .map((field) => (
          <div key={field.field_name} className='space-y-1'>
            <Text className='font-bold' size='sm'>
              {field.label}
              {field.required === '1' && (
                <span className='text-rose-600'>*</span>
              )}
            </Text>
            <select
              name={field.field_name}
              className={cn(
                'appearance-none transition duration-150 ease-in-out',
                'border border-black border-opacity-25 rounded-lg',
                'focus:outline-none focus:shadow-outline-normal',
                {
                  'text-slate-400': !formData[field.field_name],
                  'text-black': formData[field.field_name]
                },
                'bg-primary p-2 w-full',
                'text-xs sm:text-sm md:text-sm lg:text-base'
              )}
              required={field.required === '1'}
              onChange={(ev) =>
                setFormData({
                  ...formData,
                  [field.field_name]: ev.target.value
                })
              }
              defaultValue=''
              disabled={isLoading}
            >
              <option key='0' value='' hidden>
                Pilih {field.label}
              </option>
              {field.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
        ))}
      <button
        disabled={!isConnected}
        type='submit'
        className={cn(
          'py-2 w-full border border-solid bg-gray-600 disabled:bg-gray-300 rounded-lg',
          // 'text-xs sm:text-xs md:text-sm lg:text-sm',
          'text-white uppercase outline-none',
          clasNameButton
        )}
        style={{
          backgroundColor: !isConnected ? 'rgb(209 213 219)' : (app_settings?.['chat-widget']?.ui?.primary_color || 'rgb(75 85 99)')
        }}
      >
        <span className='text-center w-full'>{isLoading ? <LoadingDots className='h-3' /> : titleButton}</span>
      </button>
    </form>
  )
}

export default InputTypeForm
