import cn from 'clsx'
import _, { debounce } from 'lodash'
import moment from 'moment'
import 'moment/locale/id';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ChatConfig, getDisplayName, useApp, useChat, useLang, useVisitor } from 'alpha-chat'
import {
  getStatusByMessage,
  isSameStatusByMessage,
  getEventsByConversation,
  LocalChatEvent
} from 'alpha-chat'
import ChatBody from './ChatBody'
import ChatHeader from './ChatHeader'
import LoadingDots from '../LoadingDots'
import Text from '../Text'
import ChatAttachment, { Attachment } from './ChatAttachment'
import { DEVICE_TYPE, VIEW_TYPE } from '../../utils/constant'
import ChatActionBar from './ChatActionBar'
import LoadingSpinner from '../LoadingSpinner'
import formatValue from '../../utils/formatValue'
import Log from '../../utils/log';

const PRIMARY_BUBBLE_COLOR = '#C3F1A1'
const PRIMARY_BUTTON_COLOR = 'rgb(4, 151, 76)'

const ChatView: React.FC<{
  className?: string
  style?: React.CSSProperties
  onToggle?: () => void
  onToggleCurrentView?: (value: VIEW_TYPE) => void
  conversationKey: any
  isOpen?: boolean
  showCloseButton?: boolean
  deviceType?: DEVICE_TYPE
}> = ({ className, style, onToggle, onToggleCurrentView, conversationKey, isOpen, showCloseButton, deviceType }) => {
  const {
    isConnected,
    isFetching,
    setIsFetching,
    messages,
    sendMessage,
    pendingMessages,
    conversation,
    disableSendMessage,
    startTyping,
    markAsRead,
    clientMarkAsRead,
    newConversation,
    updateConversationMessage,
    setView
  } = useChat()
  const { app_settings } = useApp()
  const { lang, langCode } = useLang()
  const { visitor_data } = useVisitor()
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [agentTypingAt, setAgentTypingAt] = useState<Date>()
  const [agentTypingAtTimeout, setAgentTypingAtTimeout] =
    useState<NodeJS.Timeout>()
  const [markAsReadTimeout, setMarkAsReadTimeout] = useState<NodeJS.Timeout>()
  const [chat, setChat] = useState<string>('')
  const [chatEvents, setChatEvent] = useState<Array<LocalChatEvent>>([])
  const [typingAt, setTypingAt] = useState<Date>()
  const [isScrollAtBottom, setIsScrollAtBottom] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<Array<Attachment>>([])
  // const [chatEventAlreadyInAction, setChatEventAlreadyInAction] = useState<LocalChatEvent[]>([])
  const chatEventAlreadyInAction = [] as Array<LocalChatEvent>
  const [hideAction, setHideAction] = useState(false)
  const isAssignedConversation = useMemo(() => conversation?.assigned_to, [conversation])
  const isTransferedConversation = useMemo(() => conversation?.transferring_conversation, [conversation])
  
  const abortControllerUpdateMessages = useRef<AbortController | null>(null)
  
  const canSendMessage = useCallback(() => {
    if (!isConnected) return false
    if (!conversation) return false
    if (chat.length === 0 && attachments.length === 0) return false
    if (
      attachments.length > 0 &&
      !attachments.every((attachment) => Boolean(attachment.filePayload))
    )
      return false

    return !disableSendMessage
  }, [chat, attachments, conversation, disableSendMessage])

  // first time load keep scrolling to the (n-1)th element.
  // HACK: setTimeout solve this issue.
  const scrollToBottom = useCallback(
    (forced?: boolean) =>
      setTimeout(() => {
        if (chatContainerRef.current && (forced || isScrollAtBottom)) {
          chatContainerRef.current.scrollTop =
            chatContainerRef.current.scrollHeight -
            chatContainerRef.current.clientHeight
        }
      }, 100),
    [isScrollAtBottom]
  )

  const getPartyWithAgent = (): any | undefined => {
    if (!conversation) return undefined

    const parties = conversation.parties?.filter((party: any) =>
      Boolean(party.agent)
    )

    if (parties?.length > 0) return parties[0]
    else return undefined
  }

  const onClickSendMessage = () => {
    if (canSendMessage()) {
      sendMessage(
        conversation!.uid,
        chat.replace(/\n$/, "")!,
        attachments.map((attachment) => attachment.filePayload)
      )
      setChat('')
      setAttachments([])
    }
  }

  const onChangeAttachment = (files: FileList | null) => {
    if (!files || files.length === 0) return
    const maxFileSize = 1024 * 1024 * 16 // 16MB

    const oversizeFiles: Array<File> = []
    const mappedFiles: Array<Attachment> = []

    Array(...files).map((file, i) => {
      if (file.size > maxFileSize) {
        oversizeFiles.push(file)
      } else {
        mappedFiles.push({
          id: new Date().getTime() + i,
          file,
          abortController: new AbortController()
        })
      }
    })

    if (mappedFiles.length > 0) setAttachments([...attachments, ...mappedFiles])

    if (oversizeFiles.length > 0) {
      alert(
        `File dengan nama ${oversizeFiles
          .map((file) => file.name)
          .join(
            ', '
          )} melebihi batas maksimal 16MB. Coba kembali dengan file berukuran lebih kecil.`
      )
    }
  }

  useEffect(() => {
    if (!conversation && !conversationKey){
      newConversation().then(({ok, data}) => {
        if(!ok){
          alert(data?.detail || 'Failed start conversation')
          onToggleCurrentView && onToggleCurrentView('HOME')
        }
      })
    } 
    setView('CHAT')

    return () => {
      if(abortControllerUpdateMessages?.current){
        abortControllerUpdateMessages?.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    if(conversationKey){
      if(conversation && (conversation.uid == conversationKey)) return

      setIsFetching(true)
      abortControllerUpdateMessages.current = new AbortController()
      updateConversationMessage(conversationKey, abortControllerUpdateMessages.current)
    }
  }, [conversationKey])

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.onscroll = () => {
        setIsScrollAtBottom(
          chatContainerRef.current!.scrollTop ===
            chatContainerRef.current!.scrollHeight -
              chatContainerRef.current!.clientHeight
        )
      }
    }
  }, [chatContainerRef.current])

  useEffect(() => {
    scrollToBottom(true)
  }, [pendingMessages])

  useEffect(() => {
    if (isOpen) {
      scrollToBottom()
      if (markAsReadTimeout) {
        clearTimeout(markAsReadTimeout)
        setMarkAsReadTimeout(undefined)
      }
    }
  }, [messages, isOpen])

  const markAsReadMessages = useCallback(
      debounce((conversationKey) => {
        markAsRead(conversationKey)
      }, 3000)
    , []
  )

  useEffect(() => {
    if (agentTypingAt) scrollToBottom()
  }, [agentTypingAt])

  useEffect(() => {
    // window.onfocus = () => {
    //   if (ChatConfig.enableLog)
    //     console.log('onfocus:conversation:new_count', conversation?.new_count)
    //   if (isOpen && conversation && conversation.new_count > 0) {
    //     markAsReadMessages(conversation.uid)
    //   }
    // }

    if (conversation) {
      setChatEvent(getEventsByConversation(conversation))
      // const isTransferedConversation = Boolean(conversation?.transferring_conversation)
      // // const isTransferedConversation = false
      
      // setChatEvent(
      //   isTransferedConversation ?
      //   [...getEventsByConversation(conversation?.transferring_conversation), ...getEventsByConversation(conversation)] : 
      //   getEventsByConversation(conversation)
      // )
      if (conversation.new_count > 0 && isOpen && !document.hidden) {
        Log('conversation:new_count', conversation?.new_count)
        clientMarkAsRead(conversation.uid)
        markAsReadMessages(conversation.uid)
      }

      const party = getPartyWithAgent()
      if (party) {
        if (party.typing_at) {
          const duration = moment
            .duration(moment().diff(party.typing_at))
            .asSeconds()

          if (duration < 8) {
            setAgentTypingAt(party.typing_at)
            if (agentTypingAtTimeout) {
              clearTimeout(agentTypingAtTimeout)
            }

            setAgentTypingAtTimeout(
              setTimeout(() => {
                setAgentTypingAt(undefined)
                if (agentTypingAtTimeout) {
                  clearTimeout(agentTypingAtTimeout)
                  setAgentTypingAtTimeout(undefined)
                }
              }, 8000 - duration * 1000)
            )
          }
        } else {
          setAgentTypingAt(undefined)
          if (agentTypingAtTimeout) {
            clearTimeout(agentTypingAtTimeout)
            setAgentTypingAtTimeout(undefined)
          }
        }
      }
    }
  }, [conversation])

  const checkDisableSendMessage = () => {
    const lastMessage = [...messages][messages.length - 1]
    const isSelf = !Boolean(lastMessage?.sender?.agent)
    const isDisableSendMessage = Boolean(lastMessage?.interactive_body?.disable_send_message)

    if(!isSelf){
      if(isDisableSendMessage){
        // console.log('isDisableSendMessage')
        setHideAction(true)
      } else {
        setHideAction(false)
      }
    } else {
      // check before last message
        const beforeLastMessage = messages.length == 1 ? messages[0] : [...messages][messages.length - 2]
        const isBeforeLastMessageInteractiveBody = Boolean(beforeLastMessage?.interactive_body)
        if(isBeforeLastMessageInteractiveBody){
          setHideAction(true)
        } else {
          setHideAction(false)
        }
    }
  }

  useEffect(() => {
    if(messages == undefined || messages.length == 0){
      setHideAction(true)
      return
    } 

    checkDisableSendMessage()
  }, [messages])
  
  useEffect(() => {
    if(conversation){
      if(conversation.closed_at){
        setHideAction(true)
      } else {
        if(conversation.queue){
          setHideAction(false)
        }
        // console.log('conversation.closed_at false')
        // checkDisableSendMessage()
      }
    }
  }, [conversation])
  

  const primary_bubble_color = app_settings?.['chat-widget']?.ui?.primary_bubble_color || PRIMARY_BUBBLE_COLOR
  const secondary_bubble_color = app_settings?.['chat-widget']?.ui?.secondary_bubble_color || 'rgb(226 232 240)'
  const primary_bubble_text_color = app_settings?.['chat-widget']?.ui?.primary_bubble_text_color || '#000000'
  const secondary_bubble_text_color = app_settings?.['chat-widget']?.ui?.secondary_bubble_text_color || '#000000'

  const displayNameAgent = (name: any, defaultName: string = '') => {
    if(app_settings?.['chat-widget']?.default_agent_name) return app_settings?.['chat-widget']?.default_agent_name
    
    return getDisplayName(name, defaultName)
  }

  return (
    <>
      {/* Head */}
      <ChatHeader
        isConnected={isConnected}
        onToggle={onToggle}
        onToggleCurrentView={onToggleCurrentView}
        deviceType={deviceType}
        showCloseButton={showCloseButton}
      />
      {/* Body */}
      {
        !isFetching && (
          <div
            className='flex flex-col h-full overflow-y-auto'
            ref={chatContainerRef}
          >
            <div className='flex flex-col p-2 mb-2 mt-auto'>
              {messages.map((chat, i: number) => {
                const isSelf = !Boolean(chat.sender.agent)
                const sentAt = isSelf ? (chat.sent_at || chat.created_at) : chat.sent_at

                if(!sentAt) return <></>
                
                const isFisrtMsg = i == 0
                const isLastMsg = i == (messages.length - 1) 

                // get chatEvents before chat was created
                // and ignore chatEvent that already being shown
                const events = _.xorBy(
                  chatEvents.filter((chatEvent) => {
                    if(isFisrtMsg){
                      return chatEvent.eventDate.isBefore(moment(chat.created_at))|| chatEvent.eventDate.isSame(moment(chat.created_at))
                    } else if(isLastMsg) {
                      return chatEvent.eventDate.isAfter(moment(chat.created_at)) && chatEvent.eventDate.isBefore(moment(Date.now()))
                    } else {
                      return chatEvent.eventDate.isAfter(moment(chat.created_at)) && chatEvent.eventDate.isBefore(moment(messages[(i + 1)]?.created_at))
                    }
                  }),
                  chatEventAlreadyInAction,
                  'id'
                )
                // chatEventAlreadyInAction.push(...events)

                const previousMessage = i > 0 ? messages[i - 1] : undefined
                const groupWithPrevious =
                  previousMessage &&
                  // sender is not self
                  !Boolean(previousMessage.sender.agent) === isSelf &&
                  // have same status with previous message
                  isSameStatusByMessage(previousMessage, chat) &&
                  // time difference less than 1 minute
                  moment
                    .duration(
                      moment(chat.created_at).diff(previousMessage.created_at)
                    )
                    .asMinutes() <= 1 &&
                  events.length === 0
                const nextMessage =
                  i + 1 < messages.length ? messages[i + 1] : undefined
                const groupWithNext =
                  // have next message
                  nextMessage &&
                  // sender is not self
                  !Boolean(nextMessage.sender.agent) === isSelf &&
                  // have same status with next message
                  isSameStatusByMessage(nextMessage, chat) &&
                  // time difference less than 1 minute
                  moment
                    .duration(moment(nextMessage.created_at).diff(chat.created_at))
                    .asMinutes() <= 1 &&
                  // there's no chatEvents that created before next message
                  _.xorBy(
                    chatEvents.filter((chatEvent) => {
                      if(isLastMsg) {
                        return chatEvent.eventDate.isAfter(moment(nextMessage?.created_at)) && chatEvent.eventDate.isBefore(moment(Date.now()))
                      } else {
                        return chatEvent.eventDate.isAfter(moment(nextMessage?.created_at)) && chatEvent.eventDate.isBefore(moment(messages[(i + 1)]?.created_at))
                      }
                    }),
                    chatEventAlreadyInAction,
                    'id'
                  ).length === 0

                const body = chat.interactive_body || chat.body
                const lastBody =
                  conversation &&
                  conversation.last_message &&
                  (conversation.last_message.interactive_body ||
                    conversation.last_message.body)

                const showInput =
                  body &&
                  lastBody &&
                  (body === lastBody || body.text === lastBody.text) &&
                  pendingMessages.length === 0 &&
                  i === messages.length - 1

                const bubbleBackgroundColor = isSelf ? 
                      secondary_bubble_color :
                      primary_bubble_color

                const bubbleTextColor = isSelf ? 
                      secondary_bubble_text_color :
                      primary_bubble_text_color

                return (
                  <ChatBody
                    key={chat.id}
                    isSelf={isSelf}
                    groupWithPrevious={groupWithPrevious}
                    groupWithNext={groupWithNext}
                    showInput={showInput}
                    attachments={chat.attachments}
                    agent={chat.sender.agent}
                    events={events}
                    body={body}
                    status={getStatusByMessage(chat)}
                    sentAt={sentAt}
                    onClick={(text) =>
                      conversation && sendMessage(conversation.uid, text)
                    }
                    bubbleBackgroundColor={bubbleBackgroundColor}
                    bubbleTextColor={bubbleTextColor}
                  />
                )
              })}
              {pendingMessages.map((chat) => (
                <ChatBody
                  key={chat.localId}
                  body={chat.body}
                  isSelf={true}
                  status={chat.status}
                  attachments={chat.attachments}
                  bubbleBackgroundColor={secondary_bubble_color}
                  bubbleTextColor={secondary_bubble_text_color}
                  />
                  ))}
              {/* {agentTypingAt && (
                <ChatBody 
                  agent={getPartyWithAgent()?.agent}
                  bubbleBackgroundColor={primary_bubble_color}
                  bubbleTextColor={primary_bubble_text_color}>
                  <LoadingDots />
                </ChatBody>
              )} */}
              {conversation?.closed_at && (
                <>
                  <Text
                    size='none'
                    className='text-xs self-center text-center block mb-2'
                  >
                    {`${lang.ended_by} ${displayNameAgent(conversation?.closed_by)} ${lang.at} ${moment(conversation?.closed_at).locale(langCode).format('LLLL')}`}
                  </Text>
                  {/* <div className='mb-2 flex justify-center text-sm'>
                    <button
                      className={cn(
                        'p-2 border border-solid border-green-600 text-green-600 rounded',
                        'hover:border-green-800'
                      )}
                      onClick={() => newConversation()}
                    >
                      New Conversation
                    </button>
                  </div> */}
                </>
              )}
            </div>
          </div>
        )
      }
      {
        isFetching && (
          <div className="flex flex-col h-full overflow-y-auto relative">
            <div
                className={cn(
                  'absolute top-0 left-0 bottom-2 right-2 bg-white bg-opacity-50',
                  'flex justify-center items-center'
                )}
              >
              <LoadingSpinner />
            </div>
          </div>
        )
      }

      <div className={cn(
        'px-3 py-1 absolute bg-white w-full animation-in inline-flex',
        {
          'animation-in': agentTypingAt,
          'animation-out opacity-0': !agentTypingAt,
          'bottom-[53px]': !(hideAction && isConnected),
          'bottom-0':hideAction && isConnected
        }
      )}>
        <Text size='sm' className='text-slate-500'><b>{displayNameAgent(getPartyWithAgent()?.agent, 'Operator')}</b> {lang.is_typing}</Text>
      </div>

      {
        !(hideAction && isConnected) &&
        <div
          className={cn(
            'flex flex-col bg-white px-2 pb-2',
            'rounded-b-lg border-t border-t-slate-200',
            {
              'pt-3': attachments.length > 0,
              'pt-2': attachments.length === 0,
            }
          )}
        >
          <ChatAttachment
            attachments={attachments}
            conversation={conversation}
            onChange={setAttachments}
          />
          <ChatActionBar
            deviceType={deviceType}
            value={chat}
            onChangeValue={(value) => {
              setChat(value)
              if (
                conversation &&
                (!typingAt ||
                  moment.duration(moment().diff(typingAt)).asSeconds() >= 5)
              ) {
                setTypingAt(new Date())
                startTyping(conversation.uid)
              }
            }}
            disabled={!conversation || !isConnected || disableSendMessage}
            onEnter={onClickSendMessage}
            onChangeAttachment={onChangeAttachment}
            canSendMessage={canSendMessage()}
            buttonColor={app_settings?.['chat-widget']?.ui?.primary_color || PRIMARY_BUTTON_COLOR}
          />
        </div>
      }
    </>
  )
}

export default ChatView
