const en = {
   "please_wait": "Please wait...",
   "connected": "Connected",
   "connecting": "Connecting",
   "started_by": "Started By",
   "ended_by": "Ended by",
   "at": "at",
   "sending": "sending",
   "read": "read",
   "delivered": "delivered",
   "sent": "sent",
   "failed": "failed",
   "pending": "pending",
   "type_your_message": 'Type Your Message',
   "is_typing": 'is typing',

   "today": "Today",
   "welcome": "Welcome",
   "how_we_can_help_you": "How can we help you?",
   "start_conversation": "Start a conversation",
   "continue_the_conversation": "Continue the conversation",
   "hi_i_would_like": "Hi, I’d like to...",
   "talk_to_us_on_your_favorite_channels": "Talk to us on your favorite channels"
}

export default en