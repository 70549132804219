import {
  channel_email,
  channel_inapp,
  channel_instagram,
  channel_facebook,
  channel_tokopedia,
  channel_tokopedia_chat,
  channel_bukalapak,
  channel_shopee,
  channel_shopee_chat,
  channel_whatsapp,
  channel_google_mail,
  channel_linkedin,
} from "../assets/images"

export const channelTypes = {
  "email": {
    image: channel_email,
    name: "Email",
  },
  "inapp": {
    image: channel_inapp,  
    name: "InApp",
  },
  "whatsapp": {
    image: channel_whatsapp,
    name: "WhatsApp",
  },
  "instagram": {
    image: channel_instagram,
    name: "Instagram",
  },
  "facebook": {
    image: channel_facebook,
    name: "Facebook",
  },
  "tokopedia": {
    image: channel_tokopedia,
    name: "Tokopedia",
  },
  "tokopedia_chat": {
    image: channel_tokopedia_chat,
    name: "Tokopedia",
  },
  "bukalapak": {
    image: channel_bukalapak,
    name: "BukaLapak",
  },
  "shopee": {
    image: channel_shopee,
    name: "Shopee",
  },
  "shopee_chat": {
    image: channel_shopee_chat,
    name: "Shopee",
  },
  "google_mail": {
    image: channel_google_mail,
    name: "Gmail",
  },
  "linkedin": {
    image: channel_linkedin,
    name: "Gmail",
  },
  "*": {
    image: channel_inapp,
    name: "In-App Messenger",
  },
}

export function getChannelType(code, name=null) {
  if (code == "facebook" && name && name.endsWith("(IG)"))
    return channelTypes["instagram"]

  return channelTypes[code] || channelTypes["*"]
}

export default getChannelType