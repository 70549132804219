const tryParseJSON = (text: string) => {
  try {
    if(!Number(text)){
      return JSON.parse(text)
    } else {
      return undefined
    }
  } catch (e) {
    return undefined
  }
}

export default tryParseJSON
