import cn from 'clsx'
import { FC, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { uniqueMergeArray, Conversation, uploadMedia } from 'alpha-chat'
import LoadingSpinner from '../LoadingSpinner'

export type Attachment = {
  id: number
  file: File
  filePayload?: any
  uploading?: boolean
  success?: boolean
  abortController: AbortController
}

type ChatAttachment = {
  attachments: Array<Attachment>
  conversation?: Conversation
  onChange?: (attachments: Array<Attachment>) => void
}

const ChatAttachment: FC<ChatAttachment> = ({
  attachments,
  conversation,
  onChange
}) => {
  const removeAttachment = (attachment: Attachment) => {
    if (attachment.uploading) {
      attachment.abortController.abort()
    }

    if (onChange)
      onChange([
        ...attachments.filter(
          (oldAttachment) => oldAttachment.id != attachment.id
        )
      ])
  }

  useEffect(() => {
    if (!conversation || !onChange) return

    const filter = attachments.filter(
      (attachment) =>
        !attachment.uploading &&
        !attachment.filePayload &&
        attachment.success === undefined
    )

    filter.forEach((attachment) => {
      onChange(
        uniqueMergeArray(
          attachments,
          [{ ...attachment, uploading: true }],
          'id'
        )
      )
      uploadMedia(
        {
          conversationKey: conversation.uid,
          media: attachment.file
        },
        { signal: attachment.abortController.signal }
      ).then(({ ok, data }) => {
        onChange(
          uniqueMergeArray(
            attachments,
            [
              {
                ...attachment,
                filePayload: data,
                uploading: false,
                success: ok
              }
            ],
            'id'
          )
        )
      })
    })
  }, [attachments])

  return (
    <div className='flex flex-row flex-wrap relative'>
      {attachments.map((attachment, i) => (
        <div key={i} className='relative'>
          <div
            className={cn(
              'absolute bg-slate-100 opacity-25 rounded-full',
              'hover:opacity-75 cursor-pointer'
            )}
            style={{ zIndex: 50 - i, right: -2, top: -10 }}
            onClick={() => removeAttachment(attachment)}
          >
            <MdClose size={20} />
          </div>
          {attachment.uploading && (
            <div
              className={cn(
                'absolute top-0 left-0 bottom-2 right-2 bg-white bg-opacity-50',
                'flex justify-center items-center'
              )}
            >
              <LoadingSpinner />
            </div>
          )}
          <img
            src={window.URL.createObjectURL(attachment.file)}
            className={cn('h-20 mb-2 mr-2')}
          />
        </div>
      ))}
    </div>
  )
}

export default ChatAttachment
