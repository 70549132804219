import cn from 'clsx'
import { CSSProperties, FC } from 'react'

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

// const URL_BRACKETS_REGEX = /(?<=\()(.*?)(?=\))/
const LINE_BREAK_REGEX = /(?:\r\n\r\n|\r\n|\r|\n)/g

// const BIUConverter = (text: string, prevSymbols?: string[]): any[] => {
//   let modifiedText: any = text
//   const symbols: string[] = prevSymbols || []
//   const htmlFormat = [
//     { symbol: '*', tag: 'b' },
//     { symbol: '_', tag: 'em' },
//     { symbol: '~', tag: 'del' },
//     { symbol: '```', tag: 'code' },
//   ]

//   htmlFormat.forEach(({ symbol, tag }) => {
//     // This is not supported in Safari
//     // https://stackoverflow.com/questions/51568821
//     // const regex = new RegExp(`(?<=\\${symbol})(.*?)(?=\\${symbol})`, 'gm')
//     const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm')

//     const match = text.match(regex);
//     if (match) {
//       match.forEach(m => {
//         let formatted = m;
//         for (let i = 0; i < 2; i += 1) {
//           formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
//         }
//         modifiedText = modifiedText.replace(m, formatted)
//       });
//     }
//   })

//   return modifiedText
// }

export type TextProps = {
  children?: React.ReactNode | any
  className?: string
  classNameString?: string
  onClick?: () => any
  style?: CSSProperties
  accent?: boolean
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | 'none'
}

const Text: FC<TextProps> = ({
  children,
  className,
  classNameString,
  onClick,
  accent,
  size = 'default',
  ...rest
}) => {
  const escapeHtml = (unsafe: string) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
  }

  const convertSymbolsToHtmlTags = (str: string, htmlFormat: { symbol: string, tag: string }[]) => {
    htmlFormat.forEach(format => {
      const { symbol, tag } = format;
      const regex = new RegExp(`\\${symbol}(?=[^\\s])(.+?)(?=[^\\s])\\${symbol}`, 'gm')
      str = str.replace(regex, `<${tag}>$1</${tag}>`);
    });
    return str;
  }

  const renderText = (text: string) => {
    const htmlFormat = [
      { symbol: '*', tag: 'b' },
      { symbol: '_', tag: 'em' },
      { symbol: '~', tag: 'del' },
      { symbol: '```', tag: 'code' },
      // { symbol: '@', tag: 'a' },
    ]

    const sanitize = escapeHtml(text)
    let converter = sanitize;

    const converSymbols = convertSymbolsToHtmlTags(sanitize, htmlFormat)
    converter = converSymbols

    const regexEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/ig
    if (regexEmail.test(converter)) {
      converter = converter.replace(regexEmail, function(url) {
        return '<a target="_blank" rel="noreferrer" class="underline" href="mailto:' + url + '">' + url + '</a>';
      })
    }

    const regexUrl = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
    if (regexUrl.test(converter)) {
      converter = converter.replace(regexUrl, function(url) {
        return '<a target="_blank" rel="noreferrer" class="underline" href="' + url + '">' + url + '</a>';
      })
    }


    return converter
  }

  return (
    <div
      className={cn(
        {
          'text-2xs sm:text-2xs md:text-xs lg:text-xs': size === 'xs',
          'text-xs sm:text-xs md:text-sm lg:text-sm': size === 'sm',
          'text-sm sm:text-sm md:text-base lg:text-base': size === 'base',
          'text-base sm:text-base md:text-lg lg:text-lg': size === 'lg',
          'text-lg sm:text-lg md:text-xl lg:text-xl': size === 'xl',
          'text-accent': accent,
          'cursor-pointer': Boolean(onClick)
        },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {typeof children === 'string' ? 
        <div className={cn(classNameString)} dangerouslySetInnerHTML={{__html: renderText(children)}} /> : 
        children}
    </div>
  )
}

export default Text
