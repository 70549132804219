import cn from 'clsx'
import { FC, HTMLProps, MouseEventHandler } from 'react'
import { MdClose } from 'react-icons/md'
import { IoMdChatbubbles } from 'react-icons/io';
import LoadingDots from './LoadingDots'
import { DEVICE_TYPE } from '../utils/constant';
import { ChatConfig, useApp } from 'alpha-chat';


type WidgetButtonProps = HTMLProps<HTMLDivElement> & {
  deviceType?: DEVICE_TYPE
  isOpen?: boolean
  loading?: boolean
  newMessageCount: number
  onMouseEnter?: (e: MouseEventHandler<HTMLDivElement>) => void
  onMouseLeave?: (e: MouseEventHandler<HTMLDivElement>) => void
}

const IconChat = ({src} : {src?: string}) => {
  return src ? (
    <img
        src={src}
        className='w-full h-full rounded-full'
    />
  ) : (
    <IoMdChatbubbles className='w-1/2 h-1/2' />
  )
}

const WidgetButton: FC<WidgetButtonProps> = ({
  onClick,
  deviceType,
  isOpen,
  newMessageCount,
  loading,
  className,
  onMouseEnter,
  onMouseLeave
}) => {
  const { app_settings } = useApp()

  const primaryColor = app_settings?.['chat-widget']?.ui?.primary_color ? 
                           app_settings?.['chat-widget']?.ui?.primary_color : 
                           'rgb(71, 85, 105)'

  const isLauncherMessage = Boolean(app_settings?.['chat-widget']?.launcher_message)

  return (
    <div
      id="widget-button-launcher"
      className={cn(
        'select-none',
        'flex flex-shrink-0 rounded-full justify-center items-center',
        'text-white cursor-pointer',
        'w-16 h-16 relative ml-auto',
        'justify-self-end',
        // 'mr-[14px] mb-[18px]',
        {
          'mx-[10px] mb-[18px]': deviceType == 'MOBILE' || (!isLauncherMessage),
        },
        className
      )}
      style={{
        backgroundColor: primaryColor,
        boxShadow: deviceType === 'DESKTOP' ? '0px 5px 15px 4px rgb(159 159 159 / 34%)' : 'rgb(159 159 159 / 34%) 0px 4px 9px 0px'
      }}
      onClick={onClick}
      onMouseEnter={(e) => onMouseEnter && onMouseEnter(e)}
      onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
    >
      {!isOpen && newMessageCount > 0 && (
        <div
          className={cn(
            'absolute top-1.5 right-1.5 text-white bg-rose-600 rounded-full text-sm shadow-lg',
            'h-4 aspect-square w-auto flex items-center justify-center p-1'
          )}
        >
          {newMessageCount}
        </div>
      )}
      {loading ? (
        <LoadingDots />
      ) : isOpen ? (
        <MdClose className='w-1/2 h-1/2' />
      ) : (
        <IconChat src={app_settings?.['chat-widget']?.launcher_icon} />
      )}
    </div>
  )
}

export default WidgetButton
